import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';

const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P67-E1',
    audio: 'img/FriendsPlus/Page67/Audio/audio-e1-p67.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page67/E1/2.jpg', audioUrl: "img/FriendsPlus/Page67/Audio/tieude-e1-p67.mp3" },
        { url: 'img/FriendsPlus/Page67/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page67/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page67/E1/5.jpg', audioUrl: "img/FriendsPlus/Page67/Audio/sauce.mp3" },
        { url: 'img/FriendsPlus/Page67/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page67/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page67/E1/8.jpg', audioUrl: "img/FriendsPlus/Page67/Audio/jigsaw.mp3" },
        { url: 'img/FriendsPlus/Page67/E1/9.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page67/E1/10.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page67/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page67/E1/12.jpg', audioUrl: "img/FriendsPlus/Page67/Audio/august.mp3" },
        { url: 'img/FriendsPlus/Page67/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page67/E1/14.jpg', audioUrl: "img/FriendsPlus/Page67/Audio/straw.mp3" },
        { url: 'img/FriendsPlus/Page67/E1/15.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page67/E1/16.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P67-E2',
    audio: 'img/FriendsPlus/Page67/Audio/audio-e2-p67.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page67/E2/2.jpg', audioUrl: "img/FriendsPlus/Page67/Audio/tieude-e2-p67.mp3" },
        { url: 'img/FriendsPlus/Page67/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page67/E2/4.jpg', audioUrl: "img/FriendsPlus/Page67/Audio/hinh1-e2-p67.mp3" },
        { url: 'img/FriendsPlus/Page67/E2/5.jpg', audioUrl: "img/FriendsPlus/Page67/Audio/hinh2-e2-p67.mp3" },
      ],
    ],
  },

  3: {

    unit: 'Unit 9',
    id: 'SB5-U9-P67-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page67/E3/Key/answerKey.png',
    titleQuestion: [{
      num: '3', title: 'Read again. Circle the words with <i>au</i> in blue and with <i>aw</i> in green.', color: "#394893"
    }],

    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: { padding: 2 },
          green: { padding: 1, border: 'solid 2px', borderRadius: '50%', borderColor: 'green' },
          blue: { padding: 1, border: 'solid 2px', borderRadius: '50%', borderColor: 'blue' },
          //purple: { padding: 1, border: 'solid 2px', borderRadius: '50%', borderColor: 'purple' },
          // underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [

          "birthday was in August. When he woke up in",
          "the morning, he saw his presents. He got a big jigsaw",
          "puzzle. For dinner he ate noodles with oyster sauce",
          "and had a drink with a straw.",

          "In August, my sister will start",
          "school. She’ll learn to read. She’ll",
          "draw and she’ll play sports."


        ],
        answers: { '2-16': 'blue', '4-2': 'blue', '1-6': 'green', '1-20': 'green', '3-12': 'green', '6-0': 'green', '0-6': 'blue' },

        initialValue: {},
      },
      Layout: `
			   

          <div style=" position: relative; "> 
            <div><img src='img/FriendsPlus/Page67/E3/1.jpg' /></div>
            <div style="position: absolute;top: 150px;left: 25px;font-size: 18px;">
              <div><span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">Paul’s</span> <input id='0' type='Circle' /></div>
              <div><input id='1' type='Circle' /></div>
              <div><input id='2' type='Circle' /></div>
              <div><input id='3' type='Circle' /></div>
            </div>
            <div style="position: absolute;top: 190px;left: 600px;font-size: 18px;">
              <div><input id='4' type='Circle' /></div>
              <div><input id='5' type='Circle' /></div>
              <div><input id='6' type='Circle' /></div>
            </div>
          </div>
			    `,
    },
  },

  4: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P67-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',

    exerciseKey: 'img/FriendsPlus/Page67/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page67/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/3.jpg', input: true, answer: "paw" },
        { url: 'img/FriendsPlus/Page67/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page67/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page67/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/7.jpg', input: true, answer: "jigsaw" },
        { url: 'img/FriendsPlus/Page67/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page67/E4/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page67/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/11.jpg', input: true, answer: "sauce" },
        { url: 'img/FriendsPlus/Page67/E4/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page67/E4/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page67/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/15.jpg', input: true, answer: "straw" },
        { url: 'img/FriendsPlus/Page67/E4/16.jpg' },
      ],


    ],
  },
  5: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P67-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E5/1.jpg' },

      ],
    ],
  },
}

export default json;