import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  4: {
    // Exercise num
    unit: "Unit 3",
    id: "SB5-U3-P27-E4",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page27/E4/Key/answerKey.png",
    question: [],
    questionImage: [
      [
        // Column1
        { url: "img/FriendsPlus/Page27/E4/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page27/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page27/E4/3.jpg",
          input: true,
          answer: "travel",
        },
        { url: "img/FriendsPlus/Page27/E4/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page27/E4/5.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page27/E4/6.jpg" },
        {
          url: "img/FriendsPlus/Page27/E4/7.jpg",
          input: true,
          answer: "am walking / 'm walking",
        },
        { url: "img/FriendsPlus/Page27/E4/8.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page27/E4/9.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page27/E4/10.jpg" },
        { url: "img/FriendsPlus/Page27/E4/11.jpg", input: true, answer: "eat" },
        { url: "img/FriendsPlus/Page27/E4/12.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page27/E4/13.jpg" },
        {
          url: "img/FriendsPlus/Page27/E4/14.jpg",
          input: true,
          answer: "go to bed",
        },
        { url: "img/FriendsPlus/Page27/E4/15.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page27/E4/16.jpg" },
      ],
      [
        // Column9
        { url: "img/FriendsPlus/Page27/E4/17.jpg" },
        {
          url: "img/FriendsPlus/Page27/E4/18.jpg",
          input: true,
          answer: "am going / 'm going",
        },
        { url: "img/FriendsPlus/Page27/E4/19.jpg" },
        {
          url: "img/FriendsPlus/Page27/E4/20.jpg",
          input: true,
          answer: "am playing / 'm playing",
        },
        { url: "img/FriendsPlus/Page27/E4/21.jpg" },
      ],
      [
        // Column10
        { url: "img/FriendsPlus/Page27/E4/22.jpg" },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 3",
    id: "SB5-U3-P27-E5",
    audio: "",
    video: "",
    component: T6,
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page27/E5/1.jpg",
    exerciseKey: "img/FriendsPlus/Page27/E5/Key/answerKey.png",
    // titleQuestion: [{ num: '2', title: 'Write the correct words.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <div>My family and I are very happy. We <sup>1</sup> <u style='color: rgb(58,193,217);'>’re</u> going to visit our cousins</div>
              <div>next week. Our cousins live near the beach, so they <sup>2</sup> # going</div>
              <div>to take us to the beach every day. I <sup>3</sup> # going to swim in the</div>
              <div>ocean. My brother doesn’t like swimming. He <sup>4</sup> # going to play</div>
              <div>volleyball on the beach. Mom is happy because she <sup>5</sup> # going to</div>
              <div>read her new book. We <sup>6</sup> # going to have a great vacation.</div>
            </div>
            <div><img src='img/FriendsPlus/Page27/E5/2.jpg' /></div>
          </div>
          `,
        answer: ["'re", "'m", "'s", "'s", "'re"],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit 3",
    id: "SB5-U3-P27-E6",
    audio: "",
    video: "",
    component: D1,
    padding: 0,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page27/E6/Key/answerKey.png",
    question: [],
    questionImage: [
      [
        // Column1
        { url: "img/FriendsPlus/Page27/E6/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page27/E6/2.jpg" },
        { url: "img/FriendsPlus/Page27/E6/3.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page27/E6/4.jpg" },
        { url: "img/FriendsPlus/Page27/E6/5.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page27/E6/6.jpg" },
        { url: "img/FriendsPlus/Page27/E6/7.jpg", input: true, answer: "oo" },
        { url: "img/FriendsPlus/Page27/E6/8.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page27/E6/9.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page27/E6/10.jpg" },
        { url: "img/FriendsPlus/Page27/E6/11.jpg", input: true, answer: "ue" },
        { url: "img/FriendsPlus/Page27/E6/12.jpg" },
        { url: "img/FriendsPlus/Page27/E6/13.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page27/E6/14.jpg" },
        { url: "img/FriendsPlus/Page27/E6/15.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page27/E6/16.jpg" },
        { url: "img/FriendsPlus/Page27/E6/17.jpg", input: true, answer: "ea" },
        { url: "img/FriendsPlus/Page27/E6/18.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page27/E6/19.jpg" },
      ],
    ],
  },
};

export default json;
