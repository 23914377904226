import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P31-E1',
    audio: 'img/FriendsPlus/Page30/Audio/audio-e2-p30.mp3',
    video: 'Videos/e2.p30.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page30/E2/2.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/tieude-e2-p30.mp3" },
      //   { url: 'img/FriendsPlus/Page30/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E2/4.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh1-e2-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E2/5.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh2-e2-p30.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page30/E2/6.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh3-e2-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E2/7.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh4-e2-p30.mp3" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P31-E2',
    audio: 'img/FriendsPlus/Page31/Audio/audio-e2-p31.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/2.jpg', audioUrl: "img/FriendsPlus/Page31/Audio/tieude-e2-p31.mp3" },
        { url: 'img/FriendsPlus/Page31/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page31/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page31/E2/5.jpg', audioUrl: "img/FriendsPlus/Page31/Audio/doan1.mp3" },
        { url: 'img/FriendsPlus/Page31/E2/6.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page31/E2/7.jpg', audioUrl: "img/FriendsPlus/Page31/Audio/doan2.mp3" },
        { url: 'img/FriendsPlus/Page31/E2/8.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P31-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page31/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read, circle, and say.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "were / was", //0
          "were / played", //1
          "listened_to / was", //2
          "enjoyed / had", //3
          "was / were", //4
        ],
        answers: ['0-4', '1-4', '2-0', '3-0', '4-0'],
        // initialValue: ['0-4', '1-4', '2-0', '3-0', '4-0'],
        initialValue: [],
      },
      Layout: `
      <div style='line-height: 58px;'>
        <div>Amy and Leo <sup>1</sup> <b>was / </b> <b style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px;">had</b> a family concert. The concert <sup>2</sup> <b><input id='0' type='Circle' /></b> at home in Australia.</div>
        <div>The children and their parents <sup>3</sup> <b><input id='1' type='Circle' /></b> their instruments. Everyone in the audience</div>
        <div><sup>4</sup> <b><input id='2' type='Circle' /></b> the music and <sup>5</sup> <b><input id='3' type='Circle' /></b> it. It <sup>6</sup> <b><input id='4' type='Circle' /></b> a really special day.</div>
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P31-E4',
    audio: '',
    video: '',
    component: T6,
    inputSize: 120,
    titleImage: 'img/FriendsPlus/Page31/E4/1.jpg',
    exerciseKey: 'img/FriendsPlus/Page31/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Write.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div>
            <div>Yesterday there <sup>1</sup> <u style=" color:rgb(59,203,251); "> &ensp; was &ensp; </u> a concert at school. Kim and</div>
            <div>Giang <sup>2</sup> # on stage first. They <sup>3</sup> #</div>
            <div>their recorders. Nam <sup>4</sup> # next on stage.</div>
            <div>He <sup>5</sup> # his violin. The audience</div>
            <div><sup>6</sup> # at the end. They all <sup>7</sup> # the</div>
            <div>concert. It <sup>8</sup> # great!</div>
          </div>
          `,
        answer: [
          "were",
          "played",
          "was",
          "played",
          "cheered",
          "enjoyed",
          "was",
        ],
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P31-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E5/1.jpg' },
      ],
    ],
  },
}

export default json;