import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
	1: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P16-E1',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		// titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page16/2.jpg' },

			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P16-E2',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		inputSize: 600,
		titleImage: '',
		titleQuestion: [{ num: '2', title: 'Write about a boy or a girl.', color: "#445B9F" }],
		questionImage: [],
		questions: [
			{
				title:
					`
          <div style='color:rgb(82,197,235)'>Nam is going to take a towel on vacation. He’s ...</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          `,
				answer: [
					"",
					"",
					"",
				],
			},
		]
	},
	3: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P16-E3',
		audio: 'img/FriendsPlus/Page16/Audio/audio-e3-p16.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page16/E3/1.jpg' },
				{ url: 'img/FriendsPlus/Page16/E3/2.jpg', audioUrl: "img/FriendsPlus/Page16/Audio/tieude-e3-p16.mp3" },
				{ url: 'img/FriendsPlus/Page16/E3/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page16/E3/4.jpg' },
			],
		],
	},
	4: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P16-E3',
		audio: 'img/FriendsPlus/Page16/Audio/audio-e3-p16.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page16/E4/1.jpg' },
			],

		],
	},


}

export default json;