import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';


const json = {
	1: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P57-E1',
		audio: 'img/FriendsPlus/Page57/Audio/audio-e1-p57.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page57/E1/1.jpg' },
				{ url: 'img/FriendsPlus/Page57/E1/2.jpg', audioUrl: "img/FriendsPlus/Page57/Audio/tieude-e1-p57.mp3" },
				{ url: 'img/FriendsPlus/Page57/E1/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page57/E1/4.jpg' },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P57-E2',
		audio: 'img/FriendsPlus/Page57/Audio/audio-e1-p57.mp3',
		video: '',
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page57/E2/Key/answerKey.png',
		titleQuestion: [{ num: '2', title: "Listen again and draw the route to Leo's school.", color: "#2E479B" }],
		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{ boxMatchStyle: { position: 'absolute', top: "196px", left: "163px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //0
					{ boxMatchStyle: { position: 'absolute', top: "67px", left: "167px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //1
					{ boxMatchStyle: { position: 'absolute', top: "19px", left: "153px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //2
					{ boxMatchStyle: { position: 'absolute', top: "99px", left: "317px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //3
					{ boxMatchStyle: { position: 'absolute', top: "189px", left: "416px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //4
					{ boxMatchStyle: { position: 'absolute', top: "237px", left: "440px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //5
					{ boxMatchStyle: { position: 'absolute', top: "17px", left: "458px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //6
					{ boxMatchStyle: { position: 'absolute', top: "87px", left: "580px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //7
					{ boxMatchStyle: { position: 'absolute', top: "50px", left: "646px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //8
					{ boxMatchStyle: { position: 'absolute', top: "102px", left: "735px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //9
					{ boxMatchStyle: { position: 'absolute', top: "126px", left: "807px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //10
					{ boxMatchStyle: { position: 'absolute', top: "76px", left: "763px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //11
					{ boxMatchStyle: { position: 'absolute', top: "26px", left: "674px", width: 20, height: 20, backgroundColor: 'white', borderRadius: '50%' }, }, //12
				],
				answers: ['0-1', '1-3', '3-4', '4-7', '7-8', '8-9', '11-9'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page57/E2/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
      `,
		},
	},
	3: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P57-E3',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page57/E3/1.jpg' },

			],
		],
	},

	4: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P57-E4',
		audio: '',
		video: '',
		component: D1,
		hideBtnFooter: true,
		exerciseKey: '',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page57/E4/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page57/E4/2.jpg' },
				{ url: 'img/FriendsPlus/Page57/E4/3.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page57/E4/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page57/E4/5.jpg' },
				{ url: 'img/FriendsPlus/Page57/E4/6.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page57/E4/7.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page57/E4/8.jpg' },
				{ url: 'img/FriendsPlus/Page57/E4/9.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page57/E4/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page57/E4/11.jpg' },
			],

		],
	},

}

export default json;