import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P90-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Look at the email. What is it about', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page90/E1/1.jpg' },

      ],
    ],
  },

  2: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P90-E2',
    audio: 'img/FriendsPlus/Page90/Audio/audio-e2-p90.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page90/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/2.jpg', audioUrl: "img/FriendsPlus/Page90/Audio/tieude-e2-p90.mp3" },
        { url: 'img/FriendsPlus/Page90/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page90/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/5.jpg', audioUrl: "img/FriendsPlus/Page90/Audio/hinh1.mp3" },
        { url: 'img/FriendsPlus/Page90/E2/6.jpg', audioUrl: "img/FriendsPlus/Page90/Audio/hinh2.mp3" },
        { url: 'img/FriendsPlus/Page90/E2/7.jpg' },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P90-E3',
    audio: '',
    video: '',
    component: UI,
    //recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page90/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page90/E1/1.jpg' },

      ],
    ],
  },

  4: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P90-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page90/E4/Key/answerKey.png',
    inputSize: 300,
    // titleImage: "img/FriendsPlus/Page90/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Read again. Answer the question.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        
        <div style="display: flex;flex-direction: row;line-height: 58px;justify-content: space-between;width: 1100px;">
          <div>
            <div><b>1</b> Whose party is it? <u style=" color:rgb(59,203,251); ">It’s Tam’s party.</u> </div>
            <div><b>2</b> What type of party is it? #</div>
            <div><b>3</b> What date is the party? #</div>
            <div><b>4</b> Where is the party? #</div>
            <div><b>5</b> What time does it start? #</div>
            <div><b>6</b> Can Thi go to the party? #</div>
          


          </div>
      
       </div>

        `,
        answer: [
          "It's a swimming party.",
          "It's on May 8th.",
          "It's at Dam Sen Park.",
          "It starts at 3 o'clock.",
          "Yes, she can.",
        ],
      },
    ],
  },
}

export default json;