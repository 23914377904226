import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P13-E1',
    audio: 'img/FriendsPlus/Page13/Audio/audio-e1-p13.mp3',
    video: '',
    component: D1,
    padding: 0,
    maxLength: 1,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page13/E1/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page13/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/2.jpg', audioUrl: "img/FriendsPlus/Page13/Audio/tieude-e1-p13.mp3" },
        { url: 'img/FriendsPlus/Page13/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page13/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page13/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/6.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page13/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/8.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page13/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page13/E1/10.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page13/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page13/E1/12.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P13-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page13/E2/title.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P13-E3',
    audio: '',
    video: '',
    component: D1,
    padding: 2,
    exerciseKey: 'img/FriendsPlus/Page13/E3/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page13/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page13/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/3.jpg', input: true, answer: "Houses" },
        { url: 'img/FriendsPlus/Page13/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page13/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page13/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page13/E3/7.jpg', input: true, answer: "Children" },
        { url: 'img/FriendsPlus/Page13/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page13/E3/9.jpg' },
      ],

    ]
  },
}

export default json;