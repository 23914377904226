import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
	1: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P53-E1',
		audio: 'img/FriendsPlus/Page52/Audio/audio-e2-p52.mp3',
		video: 'Videos/e2.p52.mp4',
		component: UI,
		recorder: true,
		titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			// [
			//   // Column1
			//   { url: 'img/FriendsPlus/Page52/E2/1.jpg' },
			//   { url: 'img/FriendsPlus/Page52/E2/2.jpg', audioUrl: "img/FriendsPlus/Page52/Audio/tieude-e2-p52.mp3" },
			//   { url: 'img/FriendsPlus/Page52/E2/3.jpg' },
			// ],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page52/E2/4.jpg', audioUrl: "img/FriendsPlus/Page52/Audio/hinh1-e2-p52.mp3" },
				{ url: 'img/FriendsPlus/Page52/E2/5.jpg', audioUrl: "img/FriendsPlus/Page52/Audio/hinh2-e2-p52.mp3" },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page52/E2/6.jpg', audioUrl: "img/FriendsPlus/Page52/Audio/hinh3-e2-p52.mp3" },
				{ url: 'img/FriendsPlus/Page52/E2/7.jpg', audioUrl: "img/FriendsPlus/Page52/Audio/hinh4-e2-p52.mp3" },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P53-E2',
		audio: 'img/FriendsPlus/Page53/Audio/audio-e2-p53.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page53/E2/1.jpg' },
				{ url: 'img/FriendsPlus/Page53/E2/2.jpg', audioUrl: "img/FriendsPlus/Page53/Audio/tieude-e2-p53.mp3" },
				{ url: 'img/FriendsPlus/Page53/E2/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page53/E2/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page53/E2/5.jpg' },
				{ url: 'img/FriendsPlus/Page53/E2/6.jpg', audioUrl: "img/FriendsPlus/Page53/Audio/doan1.mp3" },
				{ url: 'img/FriendsPlus/Page53/E2/7.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page53/E2/8.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page53/E2/9.jpg' },
				{ url: 'img/FriendsPlus/Page53/E2/10.jpg', audioUrl: "img/FriendsPlus/Page53/Audio/doan2.mp3" },
				{ url: 'img/FriendsPlus/Page53/E2/11.jpg' },
			],

		],
	},

	3: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P53-E3',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page53/E3/Key/answerKey.png',
		inputSize: 300,
		titleImage: "img/FriendsPlus/Page53/E3/1.jpg",
		titleQuestion: [{ num: '3', title: 'Complete the sentence.', color: "#2E479B" }],
		questionImage: [],
		questions: [
			{
				title: `
				<div><b>1</b> We're late! We <u style=" color:rgb(59,203,251); ">have to walk fast</u> </div>
				<div><b>2</b> They're lost. They # again. </div>
				<div><b>3</b> The traffic light is red. He # .</div>
				<div><b>4</b> I'm at the movie theater early. I  # .</div>
				`,
				answer: [
					"have to read the map ",
					"has to stop",
					"have to wait"
				],
			},
		],
	},
	4: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P53-E4',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page53/E4/Key/answerKey.png',
		inputSize: 150,
		titleImage: "img/FriendsPlus/Page53/E4/1.jpg",
		titleQuestion: [{ num: '4', title: 'Write directions to the park.', color: "#2E479B" }],
		questionImage: [],
		questions: [
			{
				title: `
				<div><b>1</b> Go  <u style=" color:rgb(59,203,251); ">straight</u>  .</div>
				<div><b>2</b> Turn # at the traffic lights.</div>
				<div><b>3</b> Turn # at the school.</div>
				<div><b>4</b> Go # the bridge.</div>
				<div><b>5</b> The park is # the bus station. </div>
				<div><img src='img/FriendsPlus/Page53/E4/2.jpg'/></div>
				`,
				answer: [
					"left",
					"right",
					"over",
					"in front of",
				],
			},
		],
	},
	5: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P53-E5',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page53/E5/1.jpg' },
			],
		],
	},
}

export default json;