import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P66-E1',
    audio: 'img/FriendsPlus/Page66/Audio/audio-e1-p66.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page66/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/2.jpg', audioUrl: "img/FriendsPlus/Page66/Audio/tieude-e1-p66.mp3" },
        { url: 'img/FriendsPlus/Page66/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page66/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page66/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/6.jpg', audioUrl: "img/FriendsPlus/Page66/Audio/audio-e1-p66.mp3" },
        { url: 'img/FriendsPlus/Page66/E1/7.jpg' },
      ],
    ],
  },

  2: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P66-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page66/E2/1.jpg' },

      ],
    ],
  },

  3: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P66-E3',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page66/E3/Key/answerKey.png',
    inputSize: 500,
    // titleImage: "img/FriendsPlus/Page66/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Now write sentences about the moon trip.', color: "#2E479B" }],
    questionImage: [],

    questions: [
      {
        title: `
          <div style=" line-height: 58px; ">
            <div style='color: rgb(58,193,217);'> We will look at the stars this evening. We will...</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        `,
        answer: [
          "We will walk in space tomorrow.",
          "We will fly to Venus in three days.",
          "We will walk on the moon on Saturday.",
          "We will go back to Earth next week.",

        ],
      },
    ],
  },

  4: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P66-E4',
    audio: 'img/FriendsPlus/Page66/Audio/audio-e4-p66.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page66/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page66/E4/2.jpg', audioUrl: "img/FriendsPlus/Page66/Audio/tieude-e4-p66.mp3" },
        { url: 'img/FriendsPlus/Page66/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page66/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page66/E4/5.jpg' },
      ],

    ],
  },
}

export default json;