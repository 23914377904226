import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P9-E1',
    audio: 'img/FriendsPlus/Page8/Audio/audio-e2-p8.mp3',
    video: 'Videos/e2.p8.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page8/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page8/E2/2.jpg', audioUrl: "img/FriendsPlus/Page8/Audio/tieude-e2-p8.mp3" },
      //   { url: 'img/FriendsPlus/Page8/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page8/E2/4.jpg', audioUrl: "img/FriendsPlus/Page8/Audio/hinh1-e2-p8.mp3" },
        { url: 'img/FriendsPlus/Page8/E2/5.jpg', audioUrl: "img/FriendsPlus/Page8/Audio/hinh2-e2-p8.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page8/E2/6.jpg', audioUrl: "img/FriendsPlus/Page8/Audio/hinh3-e2-p8.mp3" },
        { url: 'img/FriendsPlus/Page8/E2/7.jpg', audioUrl: "img/FriendsPlus/Page8/Audio/hinh4-e2-p8.mp3" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P9-E2',
    audio: 'img/FriendsPlus/Page9/Audio/audio-e2-p9.mp3',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page9/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/2.jpg', audioUrl: "img/FriendsPlus/Page9/Audio/tieude-e2-p9.mp3" },
        { url: 'img/FriendsPlus/Page9/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page9/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page9/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/6.jpg', audioUrl: "img/FriendsPlus/Page9/Audio/doan1.mp3" },
        { url: 'img/FriendsPlus/Page9/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page9/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/9.jpg', audioUrl: "img/FriendsPlus/Page9/Audio/doan2.mp3" },
        { url: 'img/FriendsPlus/Page9/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page9/E2/11.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P9-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 400,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page9/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Write the answer.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <img src='img/FriendsPlus/Page9/E3/1.jpg'/>
            </div>
            <div>
              <div><b>1</b> Did the people in the picture live in a house? </div>
              <div style=" margin-left: 20px; "><u style='color: deepskyblue'>Yes, they did.</u></div>
              <div><b>2</b> Did the woman cook in the kitchen?</div>
              <div style=" margin-left: 20px; ">#</div>
              <div><b>3</b> Did the man work in the fields?</div>
              <div style=" margin-left: 20px; ">#</div>
              <div><b>4</b> Did the children watch TV?</div>
              <div style=" margin-left: 20px; ">#</div>
              <div><b>5</b> Did the boy help his dad?</div>
              <div style=" margin-left: 20px; ">#</div>
              <div><b>6</b> Did the girl play computer games? </div>
              <div style=" margin-left: 20px; ">#</div>
            </div>
          </div>
          `,
        answer: [
          "No, she didn't.",
          "Yes, he did.",
          "No, they didn't.",
          "Yes, he did.",
          "No, she didn't.",
        ],
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P9-E4',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page9/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Read and circle.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "started / didn’t_start",//0
          "hated / didn’t_hate",//1
          "loved / didn’t_love",//2
        ],
        answers: ['0-0', '1-4', '2-4'],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
      <div style=" display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; font-size: 22px; width: 800px; ">
        <div style="width:350px; ">
          <div><img src='img/FriendsPlus/Page9/E4/1.jpg' /></div>
          <div><span>Ly <b style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 3px; ">finished</b><b> / didn’t_finish</b> her homework last night.</span></div>
        </div>      
        <div style="width:350px; ">
          <div><img src='img/FriendsPlus/Page9/E4/2.jpg' /></div>
          <div><span>Dad <b><input id='0' type='Circle' /></b> reading a book yesterday.</span></div>
        </div>      
        <div style="width:350px; ">
          <div><img src='img/FriendsPlus/Page9/E4/3.jpg' /></div>
          <div><span>The children <b><input id='1' type='Circle' /></b>  the cake.</span></div>
        </div>      
        <div style="width:350px; ">
          <div><img src='img/FriendsPlus/Page9/E4/4.jpg' /></div>
          <div><span>He <b><input id='2' type='Circle' /></b> the dinner.</span></div>
        </div>   
      </div>
      `,
    },
  },
  5: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P9-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page9/E5/1.jpg' },
      ],
    ],
  },

}

export default json;