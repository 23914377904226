import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P49-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page49/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page49/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page49/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/3.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page49/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page49/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/6.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page49/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page49/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/9.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page49/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page49/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page49/E4/12.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page49/E4/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page49/E4/14.jpg' },
      ],

    ],
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P49-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page49/E5/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page49/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page49/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page49/E5/3.jpg', input: true, answer: "theirs" },
        { url: 'img/FriendsPlus/Page49/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page49/E5/5.jpg', input: true, answer: "ours" },
        { url: 'img/FriendsPlus/Page49/E5/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page49/E5/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page49/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page49/E5/9.jpg', input: true, answer: "hers" },
        { url: 'img/FriendsPlus/Page49/E5/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page49/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page49/E5/12.jpg', input: true, answer: "his" },
        { url: 'img/FriendsPlus/Page49/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page49/E5/14.jpg', input: true, answer: "mine" },
        { url: 'img/FriendsPlus/Page49/E5/15.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page49/E5/16.jpg' },
      ],

    ],
  },
  6: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P49-E6',
    audio: '',
    video: '',
    component: D1,
    checkDuplicated: true,
    exerciseKey: 'img/FriendsPlus/Page49/E6/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page49/E6/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page49/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page49/E6/3.jpg', input: true, answer: "coat/nose/blow" },
        { url: 'img/FriendsPlus/Page49/E6/4.jpg' },
        { url: 'img/FriendsPlus/Page49/E6/5.jpg', input: true, answer: "blue/room/flute" },
        { url: 'img/FriendsPlus/Page49/E6/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page49/E6/7.jpg' },
        { url: 'img/FriendsPlus/Page49/E6/8.jpg', input: true, answer: "light/sky" },
        { url: 'img/FriendsPlus/Page49/E6/9.jpg' },
        { url: 'img/FriendsPlus/Page49/E6/10.jpg', input: true, answer: "coat/nose/blow" },
        { url: 'img/FriendsPlus/Page49/E6/11.jpg' },
        { url: 'img/FriendsPlus/Page49/E6/12.jpg', input: true, answer: "blue/room/flute" },
        { url: 'img/FriendsPlus/Page49/E6/13.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page49/E6/14.jpg' },
        { url: 'img/FriendsPlus/Page49/E6/15.jpg', input: true, answer: "light/sky" },
        { url: 'img/FriendsPlus/Page49/E6/16.jpg' },
        { url: 'img/FriendsPlus/Page49/E6/17.jpg', input: true, answer: "coat/nose/blow" },
        { url: 'img/FriendsPlus/Page49/E6/18.jpg' },
        { url: 'img/FriendsPlus/Page49/E6/19.jpg', input: true, answer: "blue/room/flute" },
        { url: 'img/FriendsPlus/Page49/E6/20.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page49/E6/21.jpg' },
      ],

    ],
  },
}

export default json;