import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P46-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'What do you know about basketball?', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column2
        { url: 'img/FriendsPlus/Page46/E2/4.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P46-E2',
    audio: 'img/FriendsPlus/Page46/Audio/audio-e2-p46.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page46/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/2.jpg', audioUrl: "img/FriendsPlus/Page46/Audio/tieude-e2-p46.mp3" },
        { url: 'img/FriendsPlus/Page46/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page46/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P46-E3',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page46/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page46/E2/4.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P46-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page46/E4/Key/answerKey.png',
    inputSize: 160,
    // titleImage: "img/FriendsPlus/Page46/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Read again and complete the sentence.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display: flex;flex-direction: row;line-height: 58px;justify-content: space-between;width: 1100px;">
            <div>
              <div><b>1</b> Basketball started in <u style=" color:rgb(59,203,251); ">the U.S.A .</u> </div>
              <div><b>3</b> James Naismith was a #.</div>
              <div><b>5</b> Basketball is a # sport for</div>
              <div>Vietnamese.</div>
            </div>
            <div>
              <div><b>2</b> Students wanted a sport to play #.</div>
              <div><b>4</b> The baskets were for #.</div>
              <div><b>6</b> Saigon Heat first played in the</div>
              <div>ASEAN Basketball League in #.</div>
            </div>
          </div>
        `,
        answer: [
          "P.E. teacher",
          "popular",
          "inside ",
          "fruit ",
          "2012",
        ],
      },
    ],
  },
}

export default json;