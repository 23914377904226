import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P48-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page48/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page48/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page48/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/3.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page48/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page48/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/7.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/9.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page48/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/11.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page48/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/14.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page48/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/16.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page48/E1/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page48/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/19.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page48/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/21.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page48/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/23.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E1/24.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page48/E1/25.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page48/E1/26.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page48/E1/27.jpg', input: true, answer: "y" },
        { url: 'img/FriendsPlus/Page48/E1/28.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page48/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/30.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page48/E1/31.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page48/E1/32.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page48/E1/33.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E1/34.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page48/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/36.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E1/37.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page48/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/39.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page48/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/41.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/43.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page48/E1/44.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page48/E1/45.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/46.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E1/47.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/48.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page48/E1/49.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/50.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page48/E1/51.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E1/52.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E1/53.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E1/54.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page48/E1/55.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/56.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E1/57.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page48/E1/58.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P48-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page48/E2/Key/answerKey.png',
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page48/E2/1.jpg",
    // titleQuestion: [{ num: '2', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <div>Emily and Joe are both ten. On Wednesdays</div>
              <div>they go to music lessons. Emily is learning</div>
              <div>the <sup>1</sup> <u style='color: rgb(58,193,217);'>&ensp; violin &ensp;</u> and Joe is learning the</div>
              <div><sup>2</sup> # . Last week they performed in a</div>
              <div><sup>3</sup> # at the music school. Emily went</div>
              <div>up on the <sup>4</sup> # first. Then it was</div>
              <div>time for Joe. They were nervous, but they</div>
              <div>played well. The <sup>5</sup> # clapped and</div>
              <div><sup>6</sup> # . Emily and Joe were very proud.</div>
            </div>
            <div><img src='img/FriendsPlus/Page48/E2/2.jpg'/></div>
          </div>
        `,
        answer: [
          "drums",
          "concert",
          "stage",
          "audience",
          "cheered",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P48-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page48/E3/Key/answerKey.png',
    textareaStyle: { width: 800 },
    titleImage: "img/FriendsPlus/Page48/E3/1.jpg",
    // titleQuestion: [{ num: '3', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
           <div> <textarea id="0" rows="5"> </textarea> </div>  
          `,
        answer: [
          "Mia watched TV yesterday afternoon. It was about a dinosaur museum. Some of the dinosaur bones were really big. Her brother was playing soccer outside.",
        ],
      },
    ],
  },
}

export default json;