import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';

const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P7-E1',
    audio: 'img/FriendsPlus/Page7/Audio/audio-e1-p7.mp3',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page7/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/2.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/tieude-e1-7.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page7/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/5.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/o'clock.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page7/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/8.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/fifty-five.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/10.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/oh-five.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page7/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/13.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/fifty.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/15.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/ten.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page7/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/18.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/forty-five.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/20.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/fifty.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/21.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page7/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/23.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/forty.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/25.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/twenty.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/26.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page7/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/28.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/thirty-five.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/30.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/twenty-five.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/31.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page7/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/33.jpg', audioUrl: "img/FriendsPlus/Page7/Audio/thirty.mp3" },
        { url: 'img/FriendsPlus/Page7/E1/34.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page7/E1/35.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P7-E2',
    audio: '',
    video: '',
    component: T6,
    inputSize: 150,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page7/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Complete the sentence with <i>er</i> or <i>est</i>.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <div><b>1</b> Max is <u style='color: deepskyblue'>taller</u> than Holly. But Leo is the</div>
              <div style='margin-left: 20px'>#cousin. (tall)</div>
              <div><b>2</b> Leo’s skateboard is # than Amy’s skates.</div>
              <div style='margin-left: 20px'>But Holly’s bike is the#. (fast)</div>
              <div><b>3</b> Holly is the # cousin. But Max</div>
              <div style='margin-left: 20px'>is # than Leo. (young)</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page7/E2/1.jpg'/>
            </div>
          </div>
          `,
        answer: [
          "tallest",
          "faster",
          "fastest",
          "youngest",
          "younger",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P7-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 160,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page7/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Write the words in alphabetical order.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
            <div><img src='img/FriendsPlus/Page7/E3/1.jpg'/></div>
            <div style=" display: flex; justify-content: space-between; ">
              <span><b>2</b> winter summer fall</span>
              <span># &ensp; # &ensp; #</span>
            </div>
            <div style=" display: flex; justify-content: space-between; ">
              <span><b>3</b> Viet Nam Thailand Korea</span>
              <span># &ensp; # &ensp; #</span>
            </div>
            <div style=" display: flex; justify-content: space-between; ">
              <span><b>4</b> movie café museum</span>
              <span># &ensp; # &ensp; #</span>
            </div>
            <div style=" display: flex; justify-content: space-between; ">
              <span><b>5</b> bike taxi bus</span>
              <span># &ensp; # &ensp; #</span>
            </div>
          `,
        answer: [
          "fall", "summer", "winter",
          "Korea", "Thailand", "Viet Nam",
          "café", "movie", "museum",
          "bike", "bus", "taxi",
        ],
      },
    ]
  },
}

export default json;