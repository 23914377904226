import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';


const json = {
	1: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P55-E1',
		audio: 'img/FriendsPlus/Page55/Audio/audio-e1-p55.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page55/E1/1.jpg' },
				{ url: 'img/FriendsPlus/Page55/E1/2.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/tieude-e1-p55.mp3" },
				{ url: 'img/FriendsPlus/Page55/E1/3.jpg' },
			],

			[
				// Column2
				{ url: 'img/FriendsPlus/Page55/E1/4.jpg' },
				{ url: 'img/FriendsPlus/Page55/E1/5.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/s says s.mp3" },
				{ url: 'img/FriendsPlus/Page55/E1/6.jpg' },
				{ url: 'img/FriendsPlus/Page55/E1/7.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/s says z.mp3" },
				{ url: 'img/FriendsPlus/Page55/E1/8.jpg' },
				{ url: 'img/FriendsPlus/Page55/E1/9.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/s says iz.mp3" },
				{ url: 'img/FriendsPlus/Page55/E1/10.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page55/E1/11.jpg' },
				{ url: 'img/FriendsPlus/Page55/E1/12.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/bikes,laughs,it's.mp3" },
				{ url: 'img/FriendsPlus/Page55/E1/13.jpg' },
				{ url: 'img/FriendsPlus/Page55/E1/14.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/zebras,he's,plays.mp3" },
				{ url: 'img/FriendsPlus/Page55/E1/15.jpg' },
				{ url: 'img/FriendsPlus/Page55/E1/16.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/sandwiches,watches,foxes.mp3" },
				{ url: 'img/FriendsPlus/Page55/E1/17.jpg' },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P55-E2',
		audio: 'img/FriendsPlus/Page55/Audio/audio-e2-p55.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page55/E2/1.jpg' },
				{ url: 'img/FriendsPlus/Page55/E2/2.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/tieude-e2-p55.mp3" },
				{ url: 'img/FriendsPlus/Page55/E2/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page55/E2/4.jpg' },
			],

		],
	},

	3: {

		unit: 'Unit 7',
		id: 'SB5-U7-P55-E3',
		audio: '',
		video: '',
		component: DrawColor,
		exerciseKey: 'img/FriendsPlus/Page55/E3/Key/answerKey.png',
		titleQuestion: [{
			num: '3', title: 'Read again. Circle the words ending in s from <b style="color: #394893">1</b>. Use green when they say <i>s</i>, <br> use blue when they say <i>z</i>, and use purple when they say <i>iz</i>.', color: "#394893"
		}],

		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				listStyle: {
					normal: { padding: 4 },
					green: { padding: 2, border: 'solid 2px', borderRadius: '50%', borderColor: 'green' },
					blue: { padding: 2, border: 'solid 2px', borderRadius: '50%', borderColor: 'blue' },
					purple: { padding: 2, border: 'solid 2px', borderRadius: '50%', borderColor: 'purple' },
					// underline: { borderBottom: '2px solid #4285F4' },
					// square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
				},
				// limitSelect: 1,
				listWords: [
					"birthday today and he’s really happy. In the", //0
					"morning, he eats sandwiches and cake with his friend Carla.",   //1
					"Then they ride their bikes in the park.",   //2
					"In the afternoon, he plays with his new toys. In the evening, ",  //3
					"he watches a TV show about zebras and foxes. The animals are ",  //4
					"very funny and he laughs a lot.",   //5
				],
				answers: { '1-4': 'green', '2-8': 'green', '5-8': 'green', '0-6': 'blue', '3-8': 'blue', '3-16': 'blue', '4-12': 'blue', '4-20': 'blue', '1-6': 'purple', '4-2': 'purple', '4-16': 'purple' },
				initialValue: {},
			},
			Layout: `
			    <div style=" display: flex; flex-direction: row; line-height: 35px; ">
			      <div>
			        <div>It’s <span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">Tony’s</span> <input id='0' type='Circle' /></div>
			        <div><input id='1' type='Circle' /></div>
			        <div><input id='2' type='Circle' /></div>
			        <div><input id='3' type='Circle' /></div>
			        <div><input id='4' type='Circle' /></div>
			        <div><input id='5' type='Circle' /></div>
			      </div>
			      <div> <img src='img/FriendsPlus/Page55/E3/1.jpg' /> </div>
			    </div>
			    `,
		},
	},


	4: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P55-E4',
		audio: 'img/FriendsPlus/Page55/Audio/audio-e4-p55.mp3',
		video: '',
		component: DesignUnderLine,
		totalInput: 5,
		exerciseKey: 'img/FriendsPlus/Page55/E4/Key/answerKey.png',
		titleImage: '',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page55/E4/1.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/2.jpg', audioUrl: "img/FriendsPlus/Page55/Audio/tieude-e4-p55.mp3" },
				{ url: 'img/FriendsPlus/Page55/E4/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page55/E4/4.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/5.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page55/E4/6.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page55/E4/7.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/8.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page55/E4/9.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page55/E4/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page55/E4/11.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/12.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page55/E4/13.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/14.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page55/E4/15.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page55/E4/16.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/17.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page55/E4/18.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/19.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page55/E4/20.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page55/E4/21.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page55/E4/22.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/23.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page55/E4/24.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/25.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page55/E4/26.jpg' },
			],
			[
				// Column9
				{ url: 'img/FriendsPlus/Page55/E4/27.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/28.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page55/E4/29.jpg' },
				{ url: 'img/FriendsPlus/Page55/E4/30.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page55/E4/31.jpg' },
			],
			[
				// Column10
				{ url: 'img/FriendsPlus/Page55/E4/32.jpg' },
			],

		],
	},
	5: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P55-E5',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page55/E5/1.jpg' },
			],
		],
	},

}


export default json;