import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';



const json = {



  1: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P79-E1',
    audio: 'img/FriendsPlus/Page79/Audio/audio-e1-p79.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page79/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page79/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page79/E1/2.jpg', audioUrl: "img/FriendsPlus/Page79/Audio/tieude-e1-p79.mp3" },
        { url: 'img/FriendsPlus/Page79/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page79/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page79/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page79/E1/6.jpg', input: true, answer: "L" },
        { url: 'img/FriendsPlus/Page79/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page79/E1/8.jpg', input: true, answer: "P" },
        { url: 'img/FriendsPlus/Page79/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page79/E1/10.jpg' },
      ],

    ],
  },

  2: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P79-E2',
    audio: 'img/FriendsPlus/Page79/Audio/audio-e1-p79.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page79/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Listen again and circle.', color: "#434893" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "two_hours / four_hours / five_hours",  //0
          "two / six / eight",  //1
          "crocodiles / lions / bears",  //2
        ],
        answers: ['0-8', '1-8', '2-4'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;line-height: 58px;justify-content: space-between;width: 1000px;">
        <div>
          <div><b>1</b> How did Nhan get to the beach? </div>
          <div><b>2</b> How long was his journey? </div>
          <div><b>3</b> How many cousins does Lan have?  </div>
          <div><b>4</b> What was Phong’s favorite animal?</div>
        </div>
        <div>
          <div><b><span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">by car</span>/ by train / by plane</b></div>
          <div><b><input id='0' type='Circle' /></b></div>
          <div><b><input id='1' type='Circle' /></b></div>
          <div><b><input id='2' type='Circle' /></b></div>
     
        </div>        
      </div>
      `,
    },
  },

  3: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P79-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page79/E3/1.jpg' },
      ],
    ],
  },

  4: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P79-E4',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page79/E4/Key/answerKey.png',
    titleQuestion: [{ num: '', title: '', color: "" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "before / after",
          "before / after",
          "top / end",

        ],
        answers: ['0-4', '1-0', '2-4'],

        initialValue: {},
      },
      Layout: `
            <div style=" position: relative; ">
            <div> <img src='img/FriendsPlus/Page79/E4/1.jpg' /> </div>
     
            <div style="position: absolute;top: 40px;left: 525px;font-size: 20px;">
                <div><b>1</b> The stamp goes on the <b>left / <span style="padding: 3px; border: 2px solid rgb(66, 133, 244); border-radius: 50%;">right</span>.</b></div>
                <div><b>2</b> The town goes <b><input id='0' type='Circle' /></b> the</div> 
                <div>&ensp;&ensp;street</div>
                <div><b>3</b> The house number goes <b><input id='1' type='Circle' /></b> the street</div> 
              
                <div><b>4</b> The zip code goes at the <b><input id='2' type='Circle' /></b>.</div> 

            </div>
          

           

        </div>
      `,
    },
  },
  5: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P79-E5',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    padding: 0,
    textAlign: 'center',
    //maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page79/E5/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page79/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page79/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page79/E5/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page79/E5/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page79/E5/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page79/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page79/E5/7.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page79/E5/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page79/E5/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page79/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page79/E5/11.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page79/E5/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page79/E5/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page79/E5/14.jpg' },
        { url: 'img/FriendsPlus/Page79/E5/15.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page79/E5/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page79/E5/17.jpg' },
      ],

    ],
  },

}

export default json;