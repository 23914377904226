import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P63-E1',
    audio: 'img/FriendsPlus/Page63/Audio/audio-e1-p63.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 1,
    exerciseKey: 'img/FriendsPlus/Page63/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page63/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/2.jpg', audioUrl: "img/FriendsPlus/Page63/Audio/tieude-e1-p63.mp3" },
        { url: 'img/FriendsPlus/Page63/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page63/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page63/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page63/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page63/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page63/E1/10.jpg' },
      ],


    ],
  },


  2: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P63-E2',
    audio: 'img/FriendsPlus/Page63/Audio/audio-e1-p63.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page63/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page63/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page63/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page63/E2/3.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page63/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page63/E2/5.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page63/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page63/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page63/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page63/E2/9.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page63/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page63/E2/11.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page63/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page63/E2/13.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page63/E2/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page63/E2/15.jpg' },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P63-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page63/E3/1.jpg' },
      ],

    ],
  },

  4: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P63-E4',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page63/E4/Key/answerKey.png',
    titleQuestion: [{ num: '', title: '', color: "" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: {},
          circle: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
          underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          "one_foot",
          "one_sheep",
          "two_sheep",
          "one_fish",
          "one_person",
          "two_fish",
          "two_people",
          "one_tooth",
          "one_woman",
          "two_teeth",
          "two_women",

        ],
        answers: { '2-0': 'underline', '5-0': 'underline', '6-0': 'circle', '9-0': 'circle', '10-0': 'circle' },

        initialValue: {},
      },
      Layout: `
      <div style=" position: relative; ">
      <div> <img src='img/FriendsPlus/Page63/E4/1.jpg' /> </div>
      <div style="position: absolute;top: 100px;left:30px;font-size: 21px;">
        <div><b>1</b> <input id='0' type='Circle' /></div>
        <div><b>4</b> <input id='1' type='Circle' /> </div>
      </div>
    
      <div style="position: absolute;top: 100px;left: 165px;font-size: 21px;">
        <div><span style="padding: 3px; border: 2px solid rgb(66, 133, 244); border-radius: 50%;">two feet</span></div>
        <div><input id='2' type='Circle' /></div>
      </div>
      <div style="position: absolute;top: 100px;left: 300px;font-size: 21px;">
        <div><b>2</b> <input id='3' type='Circle' /></div>
        <div><b>5</b> <input id='4' type='Circle' /></div>
      </div>

      <div style="position: absolute;top: 100px;left: 450px;font-size: 21px;">
        <div><input id='5' type='Circle' /></div>
        <div><input id='6' type='Circle' /></div>
      </div>

      <div style="position: absolute;top: 100px;left: 585px;font-size: 21px;">
        <div><b>3</b> <input id='7' type='Circle' /></div>
        <div><b>6</b> <input id='8' type='Circle' /></div>
      </div>

      <div style="position: absolute;top: 100px;left: 735px;font-size: 21px;">
        <div><input id='9' type='Circle' /></div>
        <div><input id='10' type='Circle' /></div>
      </div>

  </div>
      `,
    },
  },
  5: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P63-E5',
    audio: '',
    video: '',
    component: Circle_Write,
    hideBtnFooter: true,
    exerciseKey: '',
    titleQuestion: [{ num: '5', title: 'Circle and write.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 200, },
        answers: ['', '', '',],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "story / stories", //0

        ],
        answers: ['0-2', '1-6'],
        initialValue: [],
      },
      Layout: `
      <div style='line-height: 58px;'>
        <div>There are two <b><input id='0' type='Circle' /></b>. One is called The <input id='1' width='300px'/> and .</div>
        <div>one is called The <input id='2' />. I think that the <input id='3' /> is a better .</div>
        <div>story because <input id='4' width='600px' /></div>
          <div><input id='5' width='880px' />.</div>
      </div>
      `,


    },
  },

}

export default json;