import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
    1: { // Exercise num
        unit: 'Unit 8',
        id: 'SB5-U8-P59-E1',
        audio: 'img/FriendsPlus/Page58/Audio/audio-e2-p58.mp3',
        video: 'Videos/e2.p58.mp4',
        component: UI,
        recorder: true,
        titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
        questionImage: [ // Row
            // [
            //   // Column1
            //   { url: 'img/FriendsPlus/Page58/E2/1.jpg' },
            //   { url: 'img/FriendsPlus/Page58/E2/2.jpg', audioUrl: "img/FriendsPlus/Page58/Audio/tieude-e2-p58.mp3" },
            //   { url: 'img/FriendsPlus/Page58/E2/3.jpg' },
            // ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page58/E2/4.jpg', audioUrl: "img/FriendsPlus/Page58/Audio/hinh1-e2-p58.mp3" },
                { url: 'img/FriendsPlus/Page58/E2/5.jpg', audioUrl: "img/FriendsPlus/Page58/Audio/hinh2-e2-p58.mp3" },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page58/E2/6.jpg', audioUrl: "img/FriendsPlus/Page58/Audio/hinh3-e2-p58.mp3" },
                { url: 'img/FriendsPlus/Page58/E2/7.jpg', audioUrl: "img/FriendsPlus/Page58/Audio/hinh4-e2-p58.mp3" },
            ],
        ],
    },
    2: { // Exercise num
        unit: 'Unit 8',
        id: 'SB5-U8-P59-E2',
        audio: 'img/FriendsPlus/Page59/Audio/audio-e2-P59.mp3',
        video: '',
        component: UI,
        recorder: true,
        // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page59/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page59/E2/2.jpg', audioUrl: "img/FriendsPlus/Page59/Audio/tieude-e2-P59.mp3" },
                { url: 'img/FriendsPlus/Page59/E2/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page59/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page59/E2/5.jpg', audioUrl: "img/FriendsPlus/Page59/Audio/doan1.mp3" },
                { url: 'img/FriendsPlus/Page59/E2/6.jpg', },

            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page59/E2/7.jpg', audioUrl: "img/FriendsPlus/Page59/Audio/doan2.mp3" },
                { url: 'img/FriendsPlus/Page59/E2/8.jpg' },
            ],


        ],
    },

    3: { // Exercise num
        unit: 'Unit 8',
        id: 'SB5-U8-P59-E3',
        audio: '',
        video: '',
        component: T6,
        exerciseKey: 'img/FriendsPlus/Page59/E3/Key/answerKey.png',
        inputSize: 300,
        // titleImage: "img/FriendsPlus/Page59/E3/1.jpg",
        titleQuestion: [{ num: '3', title: 'Write.', color: "#2E479B" }],
        questionImage: [],
        questions: [
            {
                title:
                    `
                        <div style=" display: flex; flex-direction: row; ">
                        <div>
                            <img src='img/FriendsPlus/Page59/E3/1.jpg'/>
                        </div>
                        <div>
                            <div><b>1</b> The modern bed is <u style='color: deepskyblue'>less comfortable than</u>(comfortable) </div>
                            <div style=" margin-left: 20px; ">the old one.</div>
                            <div><b>2</b> The old bed is # (expensive)</div>
                            <div style=" margin-left: 20px; ">the airplane bed.</div>
                            <div><b>3</b> The airplane bed is # (modern)</div>
                            <div style=" margin-left: 20px; ">the old one.</div>
                            <div><b>4</b>The old bed is # (comfortable)</div>
                            <div style=" margin-left: 20px; ">the modern one.</div>
                            
                        </div>
                        </div>

                       
                      
                       

                `,
                answer: [
                    "less expensive than",
                    "more modern than",
                    "more comfortable than"
                ],
            },
        ],
    },

    4: { // Exercise num
        unit: 'Unit 8',
        id: 'SB5-U8-P59-E4',
        audio: '',
        video: '',
        component: UI,
        recorder: true,
        // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page59/E4/1.jpg' },

            ],
        ],
    },
    5: { // Exercise num
        unit: 'Unit 8',
        id: 'SB5-U8-P59-E5',
        audio: '',
        video: '',
        component: UI,
        recorder: true,
        // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page59/E5/1.jpg' },

            ],
        ],
    },
}

export default json;