import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P75-E1',
    audio: 'img/FriendsPlus/Page74/Audio/audio-e2-p74.mp3',
    video: 'Videos/e2.p74.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page74/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page74/E2/2.jpg', audioUrl: "img/FriendsPlus/Page74/Audio/tieude-e2-p74.mp3" },
      //   { url: 'img/FriendsPlus/Page74/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page74/E2/4.jpg', audioUrl: "img/FriendsPlus/Page74/Audio/hinh1-e2-p74.mp3" },
        { url: 'img/FriendsPlus/Page74/E2/5.jpg', audioUrl: "img/FriendsPlus/Page74/Audio/hinh2-e2-p74.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page74/E2/6.jpg', audioUrl: "img/FriendsPlus/Page74/Audio/hinh3-e2-p74.mp3" },
        { url: 'img/FriendsPlus/Page74/E2/7.jpg', audioUrl: "img/FriendsPlus/Page74/Audio/hinh4-e2-p74.mp3" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P75-E2',
    audio: 'img/FriendsPlus/Page75/Audio/audio-e2-P75.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page75/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/2.jpg', audioUrl: "img/FriendsPlus/Page75/Audio/tieude-e2-p75.mp3" },
        { url: 'img/FriendsPlus/Page75/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page75/E2/4.jpg' },
      ],
      [
        // Column3 
        { url: 'img/FriendsPlus/Page75/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/6.jpg', audioUrl: "img/FriendsPlus/Page75/Audio/cau1.mp3" },
        { url: 'img/FriendsPlus/Page75/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page75/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/9.jpg', audioUrl: "img/FriendsPlus/Page75/Audio/cau2.mp3" },
        { url: 'img/FriendsPlus/Page75/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page75/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/12.jpg', audioUrl: "img/FriendsPlus/Page75/Audio/cau3.mp3" },
        { url: 'img/FriendsPlus/Page75/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page75/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page75/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/16.jpg', audioUrl: "img/FriendsPlus/Page75/Audio/cau4.mp3" },
        { url: 'img/FriendsPlus/Page75/E2/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page75/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/19.jpg', audioUrl: "img/FriendsPlus/Page75/Audio/cau5.mp3" },
        { url: 'img/FriendsPlus/Page75/E2/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page75/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/22.jpg', audioUrl: "img/FriendsPlus/Page75/Audio/cau6.mp3" },
        { url: 'img/FriendsPlus/Page75/E2/23.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page75/E2/24.jpg' },
      ],


    ],
  },

  3: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P75-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page75/E3/Key/answerKey.png',
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page75/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; ">
            <div>
            <div>&ensp; Attendant <sup>1</sup><u style='color: rgb(58,193,217);'>How much</u> luggage do you have?</div>
            <div>&ensp; Woman &ensp; We have these bags and suitcases.  </div>
            <div>&ensp; Attendant And <sup>2</sup># passengers are traveling?  </div>
            <div>&ensp; Woman &ensp; Four </div>
            <div>&ensp; Woman &ensp; <sup>3</sup># time do we have before </div>
            <div>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;  the plane leaves?  </div>
            <div>&ensp; Attendant One hour. Have a good vacation. </div>
            </div>
            <div><img src='img/FriendsPlus/Page75/E3/2.jpg' /></div>

        </div>
        `,
        answer: [
          "How many", "How much"
        ],
      },
    ],
  },


  4: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P75-E4',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page75/E4/1.jpg' },

      ],
    ],
  },
  5: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P75-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page75/E5/1.jpg' },

      ],
    ],
  },
}

export default json;