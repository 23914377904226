import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {

  1: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P69-E1',
    audio: 'img/FriendsPlus/Page69/Audio/audio-e1-p69.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page69/E1/Key/answerKey.png',
    questionImage: [ // Row

      [
        // Column1
        { url: 'img/FriendsPlus/Page69/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/2.jpg', audioUrl: "img/FriendsPlus/Page69/Audio/tieude-e1-p69.mp3" },
        { url: 'img/FriendsPlus/Page69/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page69/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page69/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/6.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page69/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/8.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page69/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page69/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page69/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/12.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page69/E1/13.jpg' },
      ],



    ],
  },

  2: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P69-E2',
    audio: 'img/FriendsPlus/Page69/Audio/audio-e1-p69.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page69/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page69/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page69/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/3.jpg', input: true, answer: "T" },
        { url: 'img/FriendsPlus/Page69/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/5.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page69/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page69/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page69/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/9.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page69/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page69/E2/11.jpg' },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P69-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page69/E3/1.jpg' },

      ],
    ],
  },

  4: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P69-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page69/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page69/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page69/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/3.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page69/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/5.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page69/E4/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page69/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page69/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/9.jpg', input: true, answer: "pancake" },
        { url: 'img/FriendsPlus/Page69/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page69/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page69/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/13.jpg', input: true, answer: "housework" },
        { url: 'img/FriendsPlus/Page69/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/15.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page69/E4/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page69/E4/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page69/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/19.jpg', input: true, answer: "doorbell" },
        { url: 'img/FriendsPlus/Page69/E4/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page69/E4/21.jpg' },
      ],


    ],
  },

  5: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P69-E5',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    padding: 0,
    textAlign: 'center',
    //maxLength: 1,
    //exerciseKey: 'img/FriendsPlus/Page69/E5/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page69/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page69/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page69/E5/3.jpg', input: true },
        { url: 'img/FriendsPlus/Page69/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page69/E5/5.jpg', input: true },
        { url: 'img/FriendsPlus/Page69/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page69/E5/7.jpg', input: true },
        { url: 'img/FriendsPlus/Page69/E5/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page69/E5/9.jpg' },
        { url: 'img/FriendsPlus/Page69/E5/10.jpg', input: true },
        { url: 'img/FriendsPlus/Page69/E5/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page69/E5/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page69/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page69/E5/14.jpg', input: true },
        { url: 'img/FriendsPlus/Page69/E5/15.jpg' },
        { url: 'img/FriendsPlus/Page69/E5/16.jpg', input: true },
        { url: 'img/FriendsPlus/Page69/E5/17.jpg' },
        { url: 'img/FriendsPlus/Page69/E5/18.jpg', input: true },
        { url: 'img/FriendsPlus/Page69/E5/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page69/E5/20.jpg' },
        { url: 'img/FriendsPlus/Page69/E5/21.jpg', input: true },
        { url: 'img/FriendsPlus/Page69/E5/22.jpg' },
      ],


    ],
  },
}

export default json;