import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P68-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Describe what is happpening in the picture', color: "#2E479B" }],

    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E1/1.jpg' }
      ],
    ],
  },

  2: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P68-E2',
    audio: 'img/FriendsPlus/Page68/Audio/audio-e2-p68.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/2.jpg', audioUrl: "img/FriendsPlus/Page68/Audio/tieude-e2-p68.mp3" },
        { url: 'img/FriendsPlus/Page68/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page68/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page68/E2/5.jpg', audioUrl: "img/FriendsPlus/Page68/Audio/doan1.mp3" },
        { url: 'img/FriendsPlus/Page68/E2/6.jpg', audioUrl: "img/FriendsPlus/Page68/Audio/doan2.mp3" },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page68/E2/7.jpg', audioUrl: "img/FriendsPlus/Page68/Audio/doan3.mp3" },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P68-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: false,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E3/1.jpg' },

      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E1/1.jpg' }
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P68-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page68/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page68/E4/2.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page68/E4/3.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/4.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page68/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page68/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/7.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page68/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page68/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/10.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page68/E4/11.jpg' },
      ],

    ],
  },

}

export default json;