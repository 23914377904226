import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {

  1: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P92-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page92/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page92/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page92/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/3.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page92/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/5.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page92/E1/6.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page92/E1/7.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page92/E1/8.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page92/E1/9.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page92/E1/10.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page92/E1/11.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page92/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/13.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page92/E1/14.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page92/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/16.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page92/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/18.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page92/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/20.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page92/E1/21.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page92/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/23.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page92/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/25.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page92/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/27.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page92/E1/28.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page92/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/30.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page92/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/32.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page92/E1/33.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/34.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page92/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/36.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page92/E1/37.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page92/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/39.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page92/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/41.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page92/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/43.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page92/E1/44.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/45.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page92/E1/46.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page92/E1/47.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/48.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page92/E1/49.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/50.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page92/E1/51.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page92/E1/52.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/53.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page92/E1/54.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page92/E1/55.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page92/E1/56.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page92/E1/57.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page92/E1/58.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page92/E1/59.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page92/E1/60.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page92/E1/61.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page92/E1/62.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/63.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page92/E1/64.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page92/E1/65.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/66.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page92/E1/67.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page92/E1/68.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page92/E1/69.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page92/E1/70.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page92/E1/71.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page92/E1/72.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page92/E1/73.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/74.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page92/E1/75.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page92/E1/76.jpg' },
      ],

    ],
  },

  2: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P92-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page92/E2/Key/answerKey.png',
    inputSize: 200,
    titleImage: "img/FriendsPlus/Page92/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        
       
 
        <div style=" display: flex; flex-direction: row; ">
            <div>
            
                      <div>I sometimes watch <sup>1</sup> <u style='color: rgb(58,193,217);'> cartoons </u>  after school,</div>
                      <div>before I do my homework. I love Tom and Jerry!</div>
                      <div>But my favorite <sup>2</sup> # is a game show on</div>
                      <div>Saturdays. When it’s finished my sister changes</div>
                      <div><sup>3</sup> # to watch her favorite show.</div>
                      <div>It’s a <sup>4</sup> # about life in space.</div> 
                      <div>Sometimes on Saturday evenings my family and</div> 
                      <div>I all watch a movie together. It is fun. Then my</div> 
                      <div>parents watch <sup>5</sup> #. They watch it every evening.</div>
              
               
            </div>
            <div><img src='img/FriendsPlus/Page92/E2/2.jpg'/></div>
          </div>




        `,
        answer: [
          "show", "channel", "documentary", "the news",

        ],
      },
    ],
  },


  3: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P92-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page92/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read and circle. Then ask and answer.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [

          "much / many", //1
          "much / many", //2
          "much / many", //3

        ],
        answers: ['0-4', '1-4', '2-0'],

        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;line-height: 48px;justify-content: space-between;width: 800px; word-spacing: 10px;">
        <div>
          <div><b>1</b> How <b><span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">much</span> / many</b> luggage do you take on vacation ?</div>
          <div><b>2</b> How <b><input id='0' type='Circle' /></b> pens are there in your pencil case ?</div>
          <div><b>3</b> How <b><input id='1' type='Circle' /></b> books do you have on your desk ?</div>
          <div><b>4</b> How <b><input id='2' type='Circle' /></b> money do you have in your pocket ?</div>
         
        </div>
      
      </div>
      `,
    },
  },
}

export default json;