import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {

  4: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P93-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    //maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page93/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page93/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page93/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page93/E4/3.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page93/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page93/E4/5.jpg', input: true, answer: "many" },
        { url: 'img/FriendsPlus/Page93/E4/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page93/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page93/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page93/E4/9.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page93/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page93/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page93/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page93/E4/13.jpg', input: true, answer: "any" },
        { url: 'img/FriendsPlus/Page93/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page93/E4/15.jpg', input: true, answer: "lots of" },
        { url: 'img/FriendsPlus/Page93/E4/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page93/E4/17.jpg' },
      ],

    ],
  },


  5: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P93-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page93/E5/Key/answerKey.png',
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page93/E5/1.jpg",
    titleQuestion: [{ num: '5', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        
        <div style=" display: flex; flex-direction: row; ">
          <div>
            <div>&ensp; <b>Mom</b>&ensp; <sup>1</sup><u style='color: rgb(58,193,217);'> Let’s </u> go to a restaurant for dinner tonight.</div>
            <div>&ensp; <b>Boy</b>&ensp; That’s a good <sup>2</sup># .</div>
            <div>&ensp; <b>Girl</b>&ensp; <sup>3</sup># don’t we go to the pizza restaurant? </div>
            <div>&ensp; <b>Mom</b>&ensp;  We ate pizza yesterday. How <sup>4</sup># going </div>
            <div>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; to a Chinese restaurant?  </div>
            <div>&ensp; <b>Boy</b>&ensp;  &ensp;<sup>5</sup># .</div>
            <div>&ensp; <b>Girl</b>&ensp; That’s <sup>6</sup># . Let’s go!</div>

          </div>


          <div><img src='img/FriendsPlus/Page93/E5/2.jpg'/></div>

        </div>
        
        
        `,
        answer: [
          "idea", "Why", "about", "OK", "great"

        ],
      },
    ],
  },

  6: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P93-E6',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 2,
    exerciseKey: 'img/FriendsPlus/Page93/E6/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page93/E6/1.jpg' },
      ],



      [
        // Column2
        { url: 'img/FriendsPlus/Page93/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page93/E6/3.jpg', input: true, answer: "ur" },
        { url: 'img/FriendsPlus/Page93/E6/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page93/E6/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page93/E6/6.jpg' },
        { url: 'img/FriendsPlus/Page93/E6/7.jpg', input: true, answer: "or" },
        { url: 'img/FriendsPlus/Page93/E6/8.jpg' },
        { url: 'img/FriendsPlus/Page93/E6/9.jpg', input: true, answer: "ir" },
        { url: 'img/FriendsPlus/Page93/E6/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page93/E6/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page93/E6/12.jpg' },
        { url: 'img/FriendsPlus/Page93/E6/13.jpg', input: true, answer: "ur" },
        { url: 'img/FriendsPlus/Page93/E6/14.jpg' },
        { url: 'img/FriendsPlus/Page93/E6/15.jpg', input: true, answer: "ur" },
        { url: 'img/FriendsPlus/Page93/E6/16.jpg' },
        { url: 'img/FriendsPlus/Page93/E6/17.jpg', input: true, answer: "or" },
        { url: 'img/FriendsPlus/Page93/E6/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page93/E6/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page93/E6/20.jpg' },
        { url: 'img/FriendsPlus/Page93/E6/21.jpg', input: true, answer: "er" },
        { url: 'img/FriendsPlus/Page93/E6/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page93/E6/23.jpg' },
      ],

    ],
  },
}

export default json;