import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P43-E1',
    audio: 'img/FriendsPlus/Page42/Audio/audio-e2-p42.mp3',
    video: 'Videos/e2.p42.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page42/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page42/E2/2.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/tieude-e2-p42.mp3" },
      //   { url: 'img/FriendsPlus/Page42/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page42/E2/4.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/hinh1-e2-p42.mp3" },
        { url: 'img/FriendsPlus/Page42/E2/5.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/hinh2-e2-p42.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page42/E2/6.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/hinh3-e2-p42.mp3" },
        { url: 'img/FriendsPlus/Page42/E2/7.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/hinh4-e2-p42.mp3" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P43-E2',
    audio: 'img/FriendsPlus/Page43/Audio/audio-e2-p43.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page43/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/2.jpg', audioUrl: "img/FriendsPlus/Page43/Audio/tieude-e2-p43.mp3" },
        { url: 'img/FriendsPlus/Page43/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page43/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page43/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/6.jpg', audioUrl: "img/FriendsPlus/Page43/Audio/cau1.mp3" },
        { url: 'img/FriendsPlus/Page43/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page43/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/9.jpg', audioUrl: "img/FriendsPlus/Page43/Audio/cau2.mp3" },
        { url: 'img/FriendsPlus/Page43/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page43/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/12.jpg', audioUrl: "img/FriendsPlus/Page43/Audio/cau3.mp3" },
        { url: 'img/FriendsPlus/Page43/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page43/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/15.jpg', audioUrl: "img/FriendsPlus/Page43/Audio/cau4.mp3" },
        { url: 'img/FriendsPlus/Page43/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page43/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/18.jpg', audioUrl: "img/FriendsPlus/Page43/Audio/cau5.mp3" },
        { url: 'img/FriendsPlus/Page43/E2/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page43/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/21.jpg', audioUrl: "img/FriendsPlus/Page43/Audio/cau6.mp3" },
        { url: 'img/FriendsPlus/Page43/E2/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page43/E2/23.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P43-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page43/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read and circle.', color: "#434893" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "her / hers.",  //0
          "my / mine",  //1
          "our / ours ",  //2
          "their / theirs.",  //3
          "your / yours",  //4

        ],
        answers: ['0-4', '1-0', '2-0', '3-4', '4-0'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;line-height: 58px;justify-content: space-between;width: 1000px;">
        <div>
          <div><b>1</b> This sandwich is <b> my / <span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">mine.</span></b></div>
          <div><b>3</b> There are five players on <b><input id='1' type='Circle' /></b> team.</div>
          <div><b>5</b> That backpack was <b><input id='3' type='Circle' /></b></div>
        </div>
        <div>
          <div><b>2</b> The red sneakers are <b><input id='0' type='Circle' /></b>  is blue.</div>
          <div><b>4</b> This is <b><input id='2' type='Circle' /></b>  trophy.</div>
          <div><b>6</b> Is this <b><input id='4' type='Circle' /></b> jacket?</div>
        </div>        
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P43-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page43/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page43/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page43/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page43/E4/3.jpg', input: true, answer: "yours" },
        { url: 'img/FriendsPlus/Page43/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page43/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page43/E4/6.jpg', input: true, answer: "ours" },
        { url: 'img/FriendsPlus/Page43/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page43/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page43/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page43/E4/10.jpg', input: true, answer: "theirs" },
        { url: 'img/FriendsPlus/Page43/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page43/E4/12.jpg', input: true, answer: "hers" },
        { url: 'img/FriendsPlus/Page43/E4/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page43/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page43/E4/15.jpg', input: true, answer: "mine" },
        { url: 'img/FriendsPlus/Page43/E4/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page43/E4/17.jpg' },
      ],

    ],
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P43-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page43/E5/1.jpg' },
      ],
    ],
  },

}

export default json;