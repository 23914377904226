import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
	1: { // Exercise num
		unit: 'Unit 4',
		id: 'SB5-U4-P34-E1',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [{ num: '1', title: 'Describe what is happening in the picture.', color: "#2E479B" }],
		questionImage: [ // Row
			// [
			// 	// Column1
			// 	{ url: 'img/FriendsPlus/Page34/E2/1.jpg' },
			// 	{ url: 'img/FriendsPlus/Page34/E2/2.jpg', audioUrl: "img/FriendsPlus/Page34/Audio/tieude-e2-p34.mp3" },
			// 	{ url: 'img/FriendsPlus/Page34/E2/3.jpg' },
			// ],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page34/E2/4.jpg' },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 4',
		id: 'SB5-U4-P34-E2',
		audio: 'img/FriendsPlus/Page34/Audio/audio-e2-p34.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page34/E2/1.jpg' },
				{ url: 'img/FriendsPlus/Page34/E2/2.jpg', audioUrl: "img/FriendsPlus/Page34/Audio/tieude-e2-p34.mp3" },
				{ url: 'img/FriendsPlus/Page34/E2/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page34/E2/4.jpg' },
			],
		],
	},
	3: { // Exercise num
		unit: 'Unit 4',
		id: 'SB5-U4-P34-E3',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page34/4.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page34/E2/4.jpg' },
			],
		],
	},
	4: { // Exercise num
		unit: 'Unit 4',
		id: 'SB5-U4-P34-E4',
		audio: '',
		video: '',
		component: D1,
		padding: 0,
		maxLength: 1,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page34/E4/Key/answerKey.png',
		question: [
		],
		questionImage: [
			[
				// Column1
				{ url: 'img/FriendsPlus/Page34/E4/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page34/E4/2.jpg' },
				{ url: 'img/FriendsPlus/Page34/E4/3.jpg', input: true, answer: "e" },
				{ url: 'img/FriendsPlus/Page34/E4/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page34/E4/5.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page34/E4/6.jpg' },
				{ url: 'img/FriendsPlus/Page34/E4/7.jpg', input: true, answer: "a" },
				{ url: 'img/FriendsPlus/Page34/E4/8.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page34/E4/9.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page34/E4/10.jpg' },
				{ url: 'img/FriendsPlus/Page34/E4/11.jpg', input: true, answer: "d" },
				{ url: 'img/FriendsPlus/Page34/E4/12.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page34/E4/13.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page34/E4/14.jpg' },
				{ url: 'img/FriendsPlus/Page34/E4/15.jpg', input: true, answer: "b" },
				{ url: 'img/FriendsPlus/Page34/E4/16.jpg' },
			],
			[
				// Column9
				{ url: 'img/FriendsPlus/Page34/E4/17.jpg' },
			],
		]
	},
}

export default json;