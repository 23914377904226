import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P81-E1',
    audio: 'img/FriendsPlus/Page80/Audio/audio-e2-p80.mp3',
    video: 'Videos/e2.p80.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page80/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page80/E2/2.jpg', audioUrl: "img/FriendsPlus/Page80/Audio/tieude-e2-p80.mp3" },
      //   { url: 'img/FriendsPlus/Page80/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page80/E2/4.jpg', audioUrl: "img/FriendsPlus/Page80/Audio/hinh1-e2-p80.mp3" },
        { url: 'img/FriendsPlus/Page80/E2/5.jpg', audioUrl: "img/FriendsPlus/Page80/Audio/hinh2-e2-p80.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page80/E2/6.jpg', audioUrl: "img/FriendsPlus/Page80/Audio/hinh3-e2-p80.mp3" },
        { url: 'img/FriendsPlus/Page80/E2/7.jpg', audioUrl: "img/FriendsPlus/Page80/Audio/hinh4-e2-p80.mp3" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P81-E2',
    audio: 'img/FriendsPlus/Page81/Audio/audio-e2-P81.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page81/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page81/E2/2.jpg', audioUrl: "img/FriendsPlus/Page81/Audio/tieude-e2-p81.mp3" },
        { url: 'img/FriendsPlus/Page81/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page81/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page81/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page81/E2/6.jpg', audioUrl: "img/FriendsPlus/Page81/Audio/cau1.mp3" },
        { url: 'img/FriendsPlus/Page81/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page81/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page81/E2/9.jpg', audioUrl: "img/FriendsPlus/Page81/Audio/cau2.mp3" },
        { url: 'img/FriendsPlus/Page81/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page81/E2/11.jpg' },
      ],


    ],
  },

  3: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P81-E3',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page81/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page81/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page81/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page81/E3/3.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page81/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page81/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page81/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page81/E3/7.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page81/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page81/E3/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page81/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page81/E3/11.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page81/E3/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page81/E3/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page81/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page81/E3/15.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page81/E3/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page81/E3/17.jpg' },
      ],

    ],
  },

  4: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P81-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page81/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page81/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page81/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page81/E4/3.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page81/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page81/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page81/E4/6.jpg', input: true, answer: "to see" },
        { url: 'img/FriendsPlus/Page81/E4/7.jpg' },
      ],


      [
        // Column4
        { url: 'img/FriendsPlus/Page81/E4/8.jpg' },
      ],




      [
        // Column5
        { url: 'img/FriendsPlus/Page81/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page81/E4/10.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page81/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page81/E4/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page81/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page81/E4/14.jpg', input: true, answer: "to take " },
        { url: 'img/FriendsPlus/Page81/E4/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page81/E4/16.jpg' },
      ],



      [
        // Column9
        { url: 'img/FriendsPlus/Page81/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page81/E4/18.jpg', input: true, answer: "to change" },
        { url: 'img/FriendsPlus/Page81/E4/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page81/E4/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page81/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page81/E4/22.jpg', input: true, answer: "to listen" },
        { url: 'img/FriendsPlus/Page81/E4/23.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page81/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page81/E4/25.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page81/E4/26.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page81/E4/27.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page81/E4/28.jpg' },
        { url: 'img/FriendsPlus/Page81/E4/29.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page81/E4/30.jpg' },
      ],



    ],
  },
  5: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P81-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page81/E5/1.jpg' },

      ],
    ],
  },
}

export default json;