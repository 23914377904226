import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P82-E1',
    audio: 'img/FriendsPlus/Page82/Audio/audio-e1-p82.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page82/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/2.jpg', audioUrl: "img/FriendsPlus/Page82/Audio/tieude-e1-p82.mp3" },
        { url: 'img/FriendsPlus/Page82/E1/3.jpg' },
      ],

      [
        // Column2
        { url: 'img/FriendsPlus/Page82/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page82/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/6.jpg', audioUrl: "img/FriendsPlus/Page82/Audio/doan1.mp3" },
        { url: 'img/FriendsPlus/Page82/E1/7.jpg', audioUrl: "img/FriendsPlus/Page82/Audio/doan2.mp3" },
        { url: 'img/FriendsPlus/Page82/E1/8.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page82/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/10.jpg', audioUrl: "img/FriendsPlus/Page82/Audio/doan1.mp3" },
        { url: 'img/FriendsPlus/Page82/E1/11.jpg', audioUrl: "img/FriendsPlus/Page82/Audio/doan3.mp3" },
        { url: 'img/FriendsPlus/Page82/E1/12.jpg' },
      ],

    ],
  },

  2: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P82-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page82/E2/1.jpg' },

      ],
    ],
  },


  3: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P82-E3',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    inputSize: 850,
    titleQuestion: [{ num: '3', title: 'Now write sentences about how often you and your friend do these things. ', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        
        <div style='color:rgb(82,197,235)'>I watch a movie once a week, but Khang watches a movie three times a week. </div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        
        `,
        answer: [
          "", "", "", "",
        ],
      },
    ],
  },

  4: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P82-E4',
    audio: 'img/FriendsPlus/Page82/Audio/audio-e4-p82.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page82/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page82/E4/2.jpg', audioUrl: "img/FriendsPlus/Page82/Audio/tieude-e4-p82.mp3" },
        { url: 'img/FriendsPlus/Page82/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page82/E4/4.jpg' },

      ],

    ],
  },

}

export default json;