import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P6-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page6/E1/1.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P6-E2',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    inputSize: 620,
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Write about what you are going to do on the weekend.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style='color:rgb(82,197,235)'>On the weekend, I’m going to ...</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          `,
        answer: [
          "",
          "",
          "",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P6-E3',
    audio: '',
    video: '',
    component: D1,
    padding: 3,
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page6/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page6/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/3.jpg', input: true, answer: "ld" },
        { url: 'img/FriendsPlus/Page6/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/5.jpg', input: true, answer: "nt" },
        { url: 'img/FriendsPlus/Page6/E3/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page6/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/8.jpg', input: true, answer: "nt" },
        { url: 'img/FriendsPlus/Page6/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/10.jpg', input: true, answer: "nd" },
        { url: 'img/FriendsPlus/Page6/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/12.jpg', input: true, answer: "nd" },
        { url: 'img/FriendsPlus/Page6/E3/13.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page6/E3/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page6/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/16.jpg', input: true, answer: "lt" },
        { url: 'img/FriendsPlus/Page6/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/18.jpg', input: true, answer: "ld" },
        { url: 'img/FriendsPlus/Page6/E3/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page6/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/21.jpg', input: true, answer: "lt" },
        { url: 'img/FriendsPlus/Page6/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/23.jpg', input: true, answer: "lt" },
        { url: 'img/FriendsPlus/Page6/E3/24.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page6/E3/25.jpg' },
      ],
    ]
  },
}

export default json;