import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

import DrawColor from '../../components/ExcerciseTypes/DrawColor';

import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P61-E1',
    audio: 'img/FriendsPlus/Page61/Audio/audio-e1-p61.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page61/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/2.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/tieude-e1-p61.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page61/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page61/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/6.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/city.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/8.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/cage.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page61/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/11.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/c says s.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/12.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/city.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/14.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/g says j.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/15.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/cage.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/16.jpg' },
      ],
      [
        // Column5

        { url: 'img/FriendsPlus/Page61/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/18.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/c says s.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/20.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/g says j.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/21.jpg' },

      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page61/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/23.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/c says s.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/24.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/ice,dance,rice.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/26.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/g says j.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/27.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/page,giafe,stage.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/28.jpg' },
      ],
      [
        // Column7

        { url: 'img/FriendsPlus/Page61/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/30.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/ice,dance,rice.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/32.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/page,giafe,stage.mp3" },
        { url: 'img/FriendsPlus/Page61/E1/33.jpg' },

      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page61/E1/34.jpg' },

      ],





    ],
  },

  2: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P61-E2',
    audio: 'img/FriendsPlus/Page61/Audio/audio-e2-p61.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page61/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/2.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/tieude-e2-p61.mp3" },
        { url: 'img/FriendsPlus/Page61/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page61/E2/4.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/hinh1-e2-p61.mp3" },
        { url: 'img/FriendsPlus/Page61/E2/5.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/hinh2-e2-p61.mp3" },
        { url: 'img/FriendsPlus/Page61/E2/6.jpg', audioUrl: "img/FriendsPlus/Page61/Audio/hinh3-e2-p61.mp3" },
      ],


    ],
  },

  3: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P61-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page61/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read again. Circle the words with <i>c</i> saying <i>s</i> and underline the words with <i>g</i> saying <i>j</i>', color: "#2E479B" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: { padding: 1 },
          circle: { padding: 0.5, border: 'solid 0.5px', borderRadius: '50%', borderColor: '#4285F4' },
          underline: { borderBottom: '1px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          "Special",//0
          "dinner tonight",//1
          "meat",//2
          "and vegetables",//3
          "Fruit with ice_cream",//4

          "Turn the pages of this",//5
          "book really fast and",//6
          "you will see a giraffe",//7
          "dancing in its cage",//8

          "SKATE CITY",//9

          "Come to the ice_rink",//10
          "in the city",//11
          "Meet your friends",//12
          "and learn to skate!",//13

        ],
        answers: { '9-2': 'circle', '3-2': 'underline', '4-4': 'circle', '8-0': 'circle', '10-6': 'circle', '11-4': 'circle', '5-4': 'underline', '7-8': 'underline', '8-6': 'underline' },

        initialValue: {},
      },
      Layout: `
          <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page61/E3/1.jpg' /> </div>
        
        
          <div style="position: absolute;top: 122px;left: 99px;font-size: 13px; text-align: center;  transform: rotate(-3deg); line-height: 1.37;">
          
            <div><b><input id='0' type='Circle' /></b></div>
            <div><b><input id='1' type='Circle' />:</b></div>
            <div><input id='2' type='Circle' /> <span style="padding: 2px; border: 1px solid rgb(66, 133, 244); border-radius: 50%;">rice</span></div>
            <div><input id='3' type='Circle' />.</div>
            <div><input id='4' type='Circle' />.</div>
          </div>
        

          <div style="position: absolute;top: 160px;left: 395px;font-size: 15px;line-height: 1.35;text-align: center;">
            <div><input id='5' type='Circle' /></div>
            <div><input id='6' type='Circle' /></div>
            <div><input id='7' type='Circle' /></div>
            <div><input id='8' type='Circle' />!</div>
            
          </div>

          <div style="position: absolute;top: 7px;left: 725px;font-size: 25px;line-height: 1.35;text-align: center; color: #ffebd0">
            <div><b><input id='9' type='Circle' /></b></div>
          
          </div>

          <div style="position: absolute;top: 157px;left: 700px;font-size: 16px;line-height: 1.35;text-align: center;">
            <div><b><input id='10' type='Circle' /></b></div>
            <div><b><input id='11' type='Circle' /></b></div> 
          </div>


          <div style="position: absolute;top: 200px;left: 725px;font-size: 13px;line-height: 1.35;text-align: center;">
            <div><b><input id='12' type='Circle' /></b></div>
            <div><b><input id='13' type='Circle' /></b></div>
          </div>

      </div>
      `,
    },
  },


  4: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P61-E4',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page61/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Circle letter <i>c</i> that says <i>s</i> and underline letter <i>g</i> that says <i>j</i>.', color: "#394893" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: {},
          circle: { padding: 0.5, border: 'solid 0.5px', borderRadius: '50%', borderColor: '#4285F4' },
          underline: { borderBottom: '1px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          "c|u|c|u|m|b|e|r",//0

          "r|a",//1
          "e",//1

          "c|r|a|y|o|n",//2
          "c|e|r|e|a|l",//3
          "c|i|t|y",//4
          "c|a|m|e|r|a",//5
          "s|t|a|g|e",//6
          "g|u|i|t|a|r",//7
          "g|o|a|l",//8
          "g|i|r|a|f|f|e",//9
          "p|a|g|e",//10
          "f|i|n|g|e|r",//11


        ],
        answers: { '4-0': 'circle', '5-0': 'circle', '10-0': 'underline', '11-4': 'underline', '7-6': 'underline' },

        initialValue: {},
      },
      Layout: `
        <div style=" position: relative; ">
            <div> <img src='img/FriendsPlus/Page61/E4/1.jpg' /> </div>
     

            <div style="position: absolute;top: 130px;left: 35px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='0' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 130px;left: 200px;font-size: 20px;line-height: 1.35;text-align: center;">
           
              <b><input id='1' type='Circle' /><span style="padding: 0.5px; border: 0.5px solid rgb(66, 133, 244); border-radius: 50%;">c</span><input id='2' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 130px;left: 400px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='3' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 130px;left: 550px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='4' type='Circle' /></b>
            </div>
            
            <div style="position: absolute;top: 297px;left: 70px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='5' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 297px;left:190px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='6' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 297px;left: 400px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='7' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 297px;left: 550px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='8' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 465px;left: 70px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='9' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 465px;left:190px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='10' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 465px;left: 400px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='11' type='Circle' /></b>
            </div>

            <div style="position: absolute;top: 465px;left: 550px;font-size: 20px;line-height: 1.35;text-align: center;">
              <b><input id='12' type='Circle' /></b>
            </div>


            

     
           
        </div>
      `,

    },
  },
  5: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-U8-P61-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page61/E5/1.jpg' },

      ],
    ],
  },



}

export default json;