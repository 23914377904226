import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P88-E1',
    audio: 'img/FriendsPlus/Page88/Audio/audio-e1-p88.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page88/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page88/E1/2.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/tieude-e1-p88.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/3.jpg' },
      ],

      [
        // Column2
        { url: 'img/FriendsPlus/Page88/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page88/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page88/E1/6.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/1.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page88/E1/8.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/2.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/9.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/3.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/10.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/4.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/11.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/5.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/12.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/6.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/13.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/7.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/14.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/8.mp3" },
        { url: 'img/FriendsPlus/Page88/E1/15.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page88/E1/16.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P88-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page88/E2/1.jpg' },
      ],
    ],
  },

  3: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P88-E3',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page88/E3/Key/answerKey.png',
    inputSize: 900,
    // titleImage: "img/FriendsPlus/Page88/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Now write a report about the Spring Festival results. ', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 58px; ">
          <div style='color: rgb(58,193,217);'>At the Spring Festival, ... came in first.</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
        </div>
      `,
        answer: [
          "At the Spring Festival, the Green team came in first./At the Spring Festival, the Green team came in 1st.",
          "The Blue team came in second./The Blue team came in 2nd.",
          "The Orange team came in third./The Orange team came in 3rd.",
          "The Purple team came in fourth./The Purple team came in 4th.",
          "The Red team came in fifth. The Yellow team came in sixth./The Red team came in 5th. The Yellow team came in 6th.",
          "The White team came in seventh./The White team came in 7th.",
          "The Brown team came in eighth./The Brown team came in 8th.",

        ],
      },
    ],
  },

  4: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P88-E4',
    audio: 'img/FriendsPlus/Page88/Audio/audio-e4-p88.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page88/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page88/E4/2.jpg', audioUrl: "img/FriendsPlus/Page88/Audio/tieude-e4-p88.mp3" },
        { url: 'img/FriendsPlus/Page88/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page88/E4/4.jpg' },
      ],
    ],
  },
}

export default json;