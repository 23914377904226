import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P12-E1',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page12/E1/2.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P12-E2',
    audio: 'img/FriendsPlus/Page12/Audio/audio-e2-p12.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/2.jpg', audioUrl: "img/FriendsPlus/Page12/Audio/tieude-e2-p12.mp3" },
        { url: 'img/FriendsPlus/Page12/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page12/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P12-E3',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/4.jpg' },
      ],
      [
        // Column0
        { url: 'img/FriendsPlus/Page12/E1/2.jpg' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P12-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page12/E4/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page12/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/3.jpg', input: true, answer: "easy" },
        { url: 'img/FriendsPlus/Page12/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page12/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/6.jpg', input: true, answer: "heavy" },
        { url: 'img/FriendsPlus/Page12/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page12/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/9.jpg', input: true, answer: "difficult" },
        { url: 'img/FriendsPlus/Page12/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page12/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/12.jpg', input: true, answer: "soft" },
        { url: 'img/FriendsPlus/Page12/E4/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page12/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/15.jpg', input: true, answer: "easy" },
        { url: 'img/FriendsPlus/Page12/E4/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page12/E4/17.jpg' },
      ],

    ]
  },

}

export default json;