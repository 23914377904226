import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P84-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Which TV show would you like watch? Why?', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1

        { url: 'img/FriendsPlus/Page84/E1/1.jpg' },

      ],

    ],
  },

  2: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P84-E2',
    audio: 'img/FriendsPlus/Page84/Audio/audio-e2-p84.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page84/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page84/E2/2.jpg', audioUrl: "img/FriendsPlus/Page84/Audio/tieude-e2-p84.mp3" },
        { url: 'img/FriendsPlus/Page84/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page84/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page84/E2/5.jpg', audioUrl: "img/FriendsPlus/Page84/Audio/hinh1-e2-p84.mp3" },
        { url: 'img/FriendsPlus/Page84/E2/6.jpg', audioUrl: "img/FriendsPlus/Page84/Audio/hinh2-e2-p84.mp3" },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page84/E2/7.jpg', audioUrl: "img/FriendsPlus/Page84/Audio/hinh3-e2-p84.mp3" },
        { url: 'img/FriendsPlus/Page84/E2/8.jpg', audioUrl: "img/FriendsPlus/Page84/Audio/hinh4-e2-p84.mp3" },
      ],
    ],

  },

  3: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-U84-E3',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page84/E3/1.jpg' },


      ],
      [
        { url: 'img/FriendsPlus/Page84/E1/1.jpg' },
      ],
    ],
  },

  4: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P84-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page84/E4/Key/answerKey.png',
    inputSize: 175,
    // titleImage: "img/FriendsPlus/Page85/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Read again and complete the sentence. ', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="display: flex;flex-direction: row;line-height: 58px;justify-content: space-between;width: 1100px;">
          <div>
            <div><b>1</b> Gru has a plan to steal the <u style=" color:rgb(59,203,251); ">moon</u> </div>
            <div><b>2</b> Jerry plays tricks on #.</div>
            <div><b>3</b> The game starts at #. </div>
            <div><b>4</b> Viet Nam’s Brainiest Kid is a #.</div>
          
          </div>
        
        </div>


        `,
        answer: [

          "Tom", "6:00", "game show"
        ],
      },
    ],
  },


}

export default json;