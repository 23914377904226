import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';

const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P83-E1',
    audio: 'img/FriendsPlus/Page83/Audio/audio-e1-p83.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page83/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page83/E1/2.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/tieude-e1-p83.mp3" },
        { url: 'img/FriendsPlus/Page83/E1/3.jpg' },
      ],

      [
        // Column2
        { url: 'img/FriendsPlus/Page83/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page83/E1/5.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/mother.mp3" },
        { url: 'img/FriendsPlus/Page83/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page83/E1/7.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/visitor.mp3" },
        { url: 'img/FriendsPlus/Page83/E1/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page83/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page83/E1/10.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/father.mp3" },
        { url: 'img/FriendsPlus/Page83/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page83/E1/12.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/doctor.mp3" },
        { url: 'img/FriendsPlus/Page83/E1/13.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page83/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page83/E1/15.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/September.mp3" },
        { url: 'img/FriendsPlus/Page83/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page83/E1/17.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/actor.mp3" },
        { url: 'img/FriendsPlus/Page83/E1/18.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P83-E2',
    audio: 'img/FriendsPlus/Page83/Audio/audio-e2-p83.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page83/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page83/E2/2.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/tieude-e2-p83.mp3" },
        { url: 'img/FriendsPlus/Page83/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page83/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page83/E2/5.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/hinh1-e2-p83.mp3" },
        { url: 'img/FriendsPlus/Page83/E2/6.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/hinh2-e2-p83.mp3" },
        { url: 'img/FriendsPlus/Page83/E2/7.jpg', audioUrl: "img/FriendsPlus/Page83/Audio/hinh3-e2-p83.mp3" },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P83-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page83/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read again. Circle the words ending with <i>er</i> and underline the words ending with <i>or</i>. ', color: "#2E479B" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: { padding: 2 },
          circle: { padding: 0.75, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
          underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          ", I will have",	//0
          "a new teacher at school.",	//1
          "Her name is Mrs. Butler.",	//2

          "When we have a visitor,",	//3
          "my mother and father",	//4
          "prepare a fantastic dinner.",	//5

          "My older brother is an",//6
          "actor. He’ll be in a play in",//7
          "October. He is a doctor in",//8
          "the play."//9

        ],
        answers: { '2-8': 'circle', '2-0': 'circle', '1-4': 'circle', '3-8': 'underline', '4-2': 'circle', '4-6': 'circle', '5-6': 'circle', '6-2': 'circle', '6-4': 'circle', '7-0': 'underline', '8-0': 'circle', '8-8': 'underline' },

        initialValue: {},
      },
      Layout: `
      <div style=" position: relative; ">
        <div> <img src='img/FriendsPlus/Page83/E3/1.jpg' /> </div>
          <div style="position: absolute;top: 195px;left:25px;font-size: 21px;">
            <div>In <span style="padding: 3px; border: 2px solid rgb(66, 133, 244); border-radius: 50%;">September</span> <input id='0' type='Circle' /></div>
            <div><input id='1' type='Circle' /></div>
            <div><input id='2' type='Circle' /></div>
    
          </div>
          <div style="position: absolute;top: 195px;left: 335px;font-size: 21px;">
            <div><input id='3' type='Circle' /></div>
            <div><input id='4' type='Circle' /></div>
            <div><input id='5' type='Circle' /></div>
          
          </div>

          <div style="position: absolute;top: 195px;left: 635px;font-size: 21px;">
            <div><input id='6' type='Circle' /></div>
            <div><input id='7' type='Circle' /></div>
            <div><input id='8' type='Circle' /></div>
            <div><input id='9' type='Circle' /></div>

          </div>
      </div>
      `,
    },
  },


  4: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P83-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    //maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page83/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page83/E4/1.jpg' },
      ],

      [
        // Column2
        { url: 'img/FriendsPlus/Page83/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page83/E4/3.jpg', input: true, answer: "doctor" },
        { url: 'img/FriendsPlus/Page83/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page83/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page83/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page83/E4/7.jpg', input: true, answer: "mother" },
        { url: 'img/FriendsPlus/Page83/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page83/E4/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page83/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page83/E4/11.jpg', input: true, answer: "teacher" },
        { url: 'img/FriendsPlus/Page83/E4/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page83/E4/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page83/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page83/E4/15.jpg', input: true, answer: "actor" },
        { url: 'img/FriendsPlus/Page83/E4/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page83/E4/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page83/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page83/E4/19.jpg', input: true, answer: "December" },
        { url: 'img/FriendsPlus/Page83/E4/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page83/E4/21.jpg' },
      ],

    ],
  },

  5: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P83-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page83/E5/1.jpg' },

      ],
    ],
  },

}

export default json;