import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P25-E1',
    audio: 'img/FriendsPlus/Page25/Audio/audio-e1-p25.mp3',
    video: '',
    component: D1,
    maxLength: 1,
    padding: 0,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page25/E1/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/2.jpg', audioUrl: "img/FriendsPlus/Page25/Audio/tieude-e1-p25.mp3" },
        { url: 'img/FriendsPlus/Page25/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page25/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page25/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/6.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page25/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/8.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page25/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page25/E1/10.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P25-E2',
    audio: 'img/FriendsPlus/Page25/Audio/audio-e1-p25.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page25/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Listen again and circle the correct word.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "dinner / lunch",//0
          "pizza_with_chicken / pizza_with_fish",//1
          "fish / salad",//2
        ],
        answers: ['0-0', '1-0', '2-0'],
        // initialValue: ['0-0', '1-0', '2-0'],
        initialValue: [],
      },
      Layout: `
      <div style=" display: flex; flex-direction: row; justify-content: space-between; text-align: center;  font-size: 22px; width: 800px; ">      
        <div style="width:200px; ">
          <div><img src='img/FriendsPlus/Page25/E2/1.jpg' /></div>
          <div><span><input id='0' type='Circle' /></span></div>
        </div>      
        <div style="width:200px; ">
          <div><img src='img/FriendsPlus/Page25/E2/2.jpg' /></div>
          <div><span><input id='1' type='Circle' /></span></div>
        </div>      
        <div style="width:200px; ">
          <div><img src='img/FriendsPlus/Page25/E2/3.jpg' /></div>
          <div><span><input id='2' type='Circle' /></span></div>
        </div>   
      </div>
      `,
    },
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P24-E3',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/4.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P25-E4',
    audio: '',
    video: '',
    component: D1,
    maxLength: 1,
    padding: 0,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page25/E4/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page25/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page25/E4/3.jpg', input: true, answer: "one/1" },
        { url: 'img/FriendsPlus/Page25/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page25/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page25/E4/6.jpg', input: true, answer: "two/2" },
        { url: 'img/FriendsPlus/Page25/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page25/E4/8.jpg', input: true, answer: "three/3" },
        { url: 'img/FriendsPlus/Page25/E4/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page25/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page25/E4/11.jpg', input: true, answer: "three/3" },
        { url: 'img/FriendsPlus/Page25/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page25/E4/13.jpg', input: true, answer: "one/1" },
        { url: 'img/FriendsPlus/Page25/E4/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page25/E4/15.jpg' },
      ],
    ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P25-E5',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    padding: 0,
    textAlign: 'center',
    exerciseKey: '',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page25/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/5.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/7.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E5/10.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page25/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/12.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/14.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E5/15.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/16.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E5/17.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E5/19.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page25/E5/20.jpg' },
      ],

    ]
  },
}

export default json;