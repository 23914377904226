import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';



const json = {
	1: { // Exercise num
		unit: 'Unit 5',
		id: 'SB5-U5-P38-E1',
		audio: 'img/FriendsPlus/Page38/Audio/audio-e1-p38.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page38/E1/1.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/2.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/tieude-e1-p38.mp3" },
				{ url: 'img/FriendsPlus/Page38/E1/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page38/E1/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page38/E1/5.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/cau1.mp3" },
				{ url: 'img/FriendsPlus/Page38/E1/6.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/cau2.mp3" },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page38/E1/7.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/cau3.mp3" },
				{ url: 'img/FriendsPlus/Page38/E1/8.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/cau4.mp3" },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page38/E1/9.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/cau5.mp3" },
				{ url: 'img/FriendsPlus/Page38/E1/10.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/cau6.mp3" },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page38/E1/11.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/cau7.mp3" },
				{ url: 'img/FriendsPlus/Page38/E1/12.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/cau8.mp3" },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page38/E1/13.jpg' },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 5',
		id: 'SB5-U5-P38-E2',
		audio: '',
		video: '',
		component: D1,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page38/E2/Key/answerKey.png',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page38/E2/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page38/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/3.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/4.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/5.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/6.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page38/E2/7.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/8.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/9.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/10.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/11.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page38/E2/12.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/13.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/14.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/15.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/16.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page38/E2/17.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/18.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/19.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/20.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/21.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page38/E2/22.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/23.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/24.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/25.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/26.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page38/E2/27.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/28.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/29.jpg' },
				{ url: 'img/FriendsPlus/Page38/E2/30.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page38/E2/31.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page38/E2/32.jpg' },
			],
		],
	},
	3: { // Exercise num
		unit: 'Unit 5',
		id: 'SB5-U5-P38-E3',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page38/E3/Key/answerKey.png',
		inputSize: 800,
		// titleImage: "img/FriendsPlus/Page38/E3/1.jpg",
		titleQuestion: [{ num: '3', title: 'Write about what you and your friend did on another sheet of paper.', color: "#2E479B" }],
		questionImage: [],
		questions: [
			{
				title: `
					<div>#</div>
					<div>#</div>
					<div>#</div>
					<div>#</div>
					<div>#</div>
				`,
				answer: [
					"",
					"",
					"",
					"",
					"",
				],
			},
		],
	},
	4: { // Exercise num
		unit: 'Unit 5',
		id: 'SB5-U5-P38-E4',
		audio: 'img/FriendsPlus/Page38/Audio/audio-e4-p38.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page38/E4/1.jpg' },
				{ url: 'img/FriendsPlus/Page38/E4/2.jpg', audioUrl: "img/FriendsPlus/Page38/Audio/tieude-e4-p38.mp3" },
				{ url: 'img/FriendsPlus/Page38/E4/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page38/E4/4.jpg' },

			],
		],
	},
}

export default json;
