import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-p65-E1',
    audio: 'img/FriendsPlus/Page64/Audio/audio-e2-p64.mp3',
    video: 'Videos/e2.p64.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page64/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page64/E2/2.jpg', audioUrl: "img/FriendsPlus/Page64/Audio/tieude-e2-p64.mp3" },
      //   { url: 'img/FriendsPlus/Page64/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page64/E2/4.jpg', audioUrl: "img/FriendsPlus/Page64/Audio/hinh1-e2-p64.mp3" },
        { url: 'img/FriendsPlus/Page64/E2/5.jpg', audioUrl: "img/FriendsPlus/Page64/Audio/hinh2-e2-p64.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page64/E2/6.jpg', audioUrl: "img/FriendsPlus/Page64/Audio/hinh3-e2-p64.mp3" },
        { url: 'img/FriendsPlus/Page64/E2/7.jpg', audioUrl: "img/FriendsPlus/Page64/Audio/hinh4-e2-p64.mp3" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-p65-E2',
    audio: 'img/FriendsPlus/Page65/Audio/audio-e2-p65.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page65/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/2.jpg', audioUrl: "img/FriendsPlus/Page65/Audio/tieude-e2-p65.mp3" },
        { url: 'img/FriendsPlus/Page65/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page65/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page65/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/6.jpg', audioUrl: "img/FriendsPlus/Page65/Audio/cau1.mp3" },
        { url: 'img/FriendsPlus/Page65/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page65/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/9.jpg', audioUrl: "img/FriendsPlus/Page65/Audio/cau2.mp3" },
        { url: 'img/FriendsPlus/Page65/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page65/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/12.jpg', audioUrl: "img/FriendsPlus/Page65/Audio/cau3.mp3" },
        { url: 'img/FriendsPlus/Page65/E2/13.jpg' },

      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page65/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/15.jpg', audioUrl: "img/FriendsPlus/Page65/Audio/cau4.mp3" },
        { url: 'img/FriendsPlus/Page65/E2/16.jpg' },

      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page65/E2/17.jpg' },
      ],
    ],
  },

  3: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P65-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 100,
    exerciseKey: 'img/FriendsPlus/Page65/E3/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page65/E3/1.jpg",
    // titleQuestion: [{ num: '3', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; ">
        <div>
          <div>In the future, maybe people<sup>1</sup> <u style=" color:rgb(59,203,251); "> will</u>  go to the moon</div>
          <div>for vacations. They <sup>2</sup> # travel by rocket or</div>
          <div>spaceship. They. <sup>3</sup> # travel by airplane.</div>
          <div>They <sup>4</sup> # wear astronauts' clothes.</div>
          <div>They <sup>5</sup> # eat normal food. They <sup>6</sup> # eat</div>
          <div>space food through a tube. But they <sup>7</sup> # get out</div>
          <div>of the spaceship. It <sup>8</sup> # be too dangerous.</div>
          <div>They <sup>9</sup> # see amazing things! </div>
        </div>
        <div><img src='img/FriendsPlus/Page65/E3/2.jpg' /></div>
      </div>
        `,
        answer: [
          "will",
          "won't",
          "will",
          "won't",
          "will",
          "won't",
          "will",
          "will",
        ],
      },
    ],
  },

  4: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P65-E4',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page65/E4/1.jpg' },

      ],
    ],
  },

  5: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P65-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page65/E5/1.jpg' },

      ],
    ],
  },
}

export default json;