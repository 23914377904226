import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {

  1: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P85-E1',
    audio: 'img/FriendsPlus/Page85/Audio/audio-e1-p85.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page85/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page85/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page85/E1/2.jpg', audioUrl: "img/FriendsPlus/Page85/Audio/tieude-e1-p85.mp3" },
        { url: 'img/FriendsPlus/Page85/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page85/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page85/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page85/E1/6.jpg', input: true, answer: "T" },
        { url: 'img/FriendsPlus/Page85/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page85/E1/8.jpg', input: true, answer: "A" },
        { url: 'img/FriendsPlus/Page85/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page85/E1/10.jpg', input: true, answer: "H" },
        { url: 'img/FriendsPlus/Page85/E1/11.jpg' },
      ],


    ],
  },

  2: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P85-E2',
    audio: 'img/FriendsPlus/Page85/Audio/audio-e1-p85.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page85/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page85/E2/1.jpg' },
      ],

      [
        // Column2
        { url: 'img/FriendsPlus/Page85/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/3.jpg', input: true, answer: "T" },
        { url: 'img/FriendsPlus/Page85/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page85/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page85/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/7.jpg', input: true, answer: "T" },
        { url: 'img/FriendsPlus/Page85/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/9.jpg', input: true, answer: "T" },
        { url: 'img/FriendsPlus/Page85/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page85/E2/11.jpg' },
      ],

      [
        // Column6
        { url: 'img/FriendsPlus/Page85/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/13.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page85/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/15.jpg', input: true, answer: "T" },
        { url: 'img/FriendsPlus/Page85/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page85/E2/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page85/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/19.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page85/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/21.jpg', input: true, answer: "T" },
        { url: 'img/FriendsPlus/Page85/E2/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page85/E2/23.jpg' },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P85-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page85/E3/1.jpg' },

      ],
    ],
  },

  4: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P85-E4',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    padding: 0,
    textAlign: 'center',
    //maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page85/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page85/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page85/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page85/E4/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page85/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page85/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page85/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page85/E4/7.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page85/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page85/E4/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page85/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page85/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page85/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page85/E4/13.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page85/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page85/E4/15.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page85/E4/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page85/E4/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page85/E4/18.jpg' },
      ],

    ],
  },

  5: { // Exercise num
    unit: 'Unit 11',
    id: 'SB5-U11-P85-E5',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page85/E5/Key/answerKey.png',
    inputSize: 900,
    // titleImage: "img/FriendsPlus/Page85/E5/1.jpg",
    titleQuestion: [{ num: '5', title: '  Write about your favorite TV show.Use at least one word from <big style="color:#445B9F">4</big> What <br/> happens on the show? How do the people feel?', color: "#2E479B" }],


    questionImage: [],
    questions: [
      {
        title: `
        
        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>

        `,
        answer: [
          "", "", "", "", "",
        ],
      },
    ],
  },

}

export default json;