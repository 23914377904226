import UI from '../../components/ExcerciseTypes/Design/UserInterface';


const json = {

	1: { // Exercise num
		unit: 'Starter',
		id: 'SB5-S-P4-E1',
		audio: 'img/FriendsPlus/Page4/Audio/audio-e1-p4.mp3',
		video: '',
		component: UI,
		recorder: true,
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page4/E1/1.jpg' },
				{ url: 'img/FriendsPlus/Page4/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/tieude-e1-p4.mp3' },
				{ url: 'img/FriendsPlus/Page4/E1/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page4/E1/4.jpg' },
			],

		],
	},
	2: { // Exercise num
		unit: 'Starter',
		id: 'SB5-S-P4-E2',
		audio: '',
		video: 'Videos/e2.p4.mp4',
		component: UI,
		recorder: true,
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page4/E2/1.jpg' },
				{ url: 'img/FriendsPlus/Page4/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/tieude-e2-p4.mp3' },
				{ url: 'img/FriendsPlus/Page4/E2/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page4/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/anh1-e2-p4.mp3' },
				{ url: 'img/FriendsPlus/Page4/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/anh2-e2-p4.mp3' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page4/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/anh3-e2-p4.mp3' },
				{ url: 'img/FriendsPlus/Page4/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/anh4-e2-p4.mp3' },
			],
		],
	},

}

export default json;