import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P32-E1',
    audio: 'img/FriendsPlus/Page32/Audio/audio-e1-p32.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page32/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/2.jpg', audioUrl: "img/FriendsPlus/Page32/Audio/tieude-e1-p32.mp3" },
        { url: 'img/FriendsPlus/Page32/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page32/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page32/E1/5.jpg', audioUrl: "img/FriendsPlus/Page32/Audio/cau1.mp3" },
        { url: 'img/FriendsPlus/Page32/E1/6.jpg', audioUrl: "img/FriendsPlus/Page32/Audio/cau2.mp3" },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page32/E1/7.jpg', audioUrl: "img/FriendsPlus/Page32/Audio/cau3.mp3" },
        { url: 'img/FriendsPlus/Page32/E1/8.jpg', audioUrl: "img/FriendsPlus/Page32/Audio/cau4.mp3" },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page32/E1/9.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P32-E2',
    audio: '',
    video: '',
    component: T6,
    inputSize: 200,
    titleImage: 'img/FriendsPlus/Page32/E2/1.jpg',
    exerciseKey: 'img/FriendsPlus/Page32/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Look at the calendar and write.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <div>Hi, my name is Hoa. Look, this is my calendar.</div>
              <div><sup>1</sup> <u style=" color:rgb(59,203,251); "> Yesterday </u>  , it was my birthday. I turned ten.</div>
              <div>We had a family concert <sup>2</sup> # .</div>
              <div>I played my recorder. <sup>3</sup> # , my</div>
              <div>friend Thu had a party at her house. We had</div>
              <div>dinner at Grandma’s house <sup>4</sup> # .</div>
              <div>We visit her every month.</div>
            </div>
            <div><img src='img/FriendsPlus/Page32/E2/2.jpg' /></div>
          </div>
          `,
        answer: [
          "last week",
          "Five days ago",
          "four weeks ago",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P32-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Look at the calendar again. Talk about Hoa.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page32/E3/1.jpg' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P32-E4',
    audio: 'img/FriendsPlus/Page32/Audio/audio-e4-p32.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Look at the calendar again. Talk about Hoa.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page32/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page32/E4/2.jpg', audioUrl: "img/FriendsPlus/Page32/Audio/tieude-e4-p32.mp3" },
        { url: 'img/FriendsPlus/Page32/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page32/E4/4.jpg' },
      ],


    ],
  },

}

export default json;