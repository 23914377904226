import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P23-E1',
    audio: 'img/FriendsPlus/Page23/Audio/audio-e1-p23.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page23/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/2.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/tieude-e1-p23.mp3" },
        { url: 'img/FriendsPlus/Page23/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page23/E1/4.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/long a.mp3" },
        { url: 'img/FriendsPlus/Page23/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/6.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/long e.mp3" },
        { url: 'img/FriendsPlus/Page23/E1/7.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page23/E1/8.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/train.mp3" },
        { url: 'img/FriendsPlus/Page23/E1/9.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/tray.mp3" },
        { url: 'img/FriendsPlus/Page23/E1/10.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/cake.mp3" },
        { url: 'img/FriendsPlus/Page23/E1/11.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/tree.mp3" },
        { url: 'img/FriendsPlus/Page23/E1/12.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/leaves.mp3" },
        { url: 'img/FriendsPlus/Page23/E1/13.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/key.mp3" },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P23-E2',
    audio: 'img/FriendsPlus/Page23/Audio/audio-e2-p23.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page23/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/2.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/tieude-e2-p23.mp3" },
        { url: 'img/FriendsPlus/Page23/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page23/E2/4.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/hinh1-e2-p23.mp3" },
        { url: 'img/FriendsPlus/Page23/E2/5.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/hinh2-e2-p23.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page23/E2/6.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/hinh3-e2-p23.mp3" },
        { url: 'img/FriendsPlus/Page23/E2/7.jpg', audioUrl: "img/FriendsPlus/Page23/Audio/hinh2-e2-p23.mp3" },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P23-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page23/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read again. Circle the words with long <i>a</i> sounds and underline the words <br/>with long <i>e</i> sounds.', color: "#394893" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: { padding: 3 },
          circle: { padding: 1, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
          underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          "out at the rain. I can see some big trees with big, orange leaves.",	//0
          "There’s a party today. Lots of children are here to play and have races. Look, there is a cake on a tray.",	//1
          "Oh no! I can’t find my key.",	//2
        ],
        answers: { '0-6': 'circle', '1-20': 'circle', '1-26': 'circle', '1-36': 'circle', '1-42': 'circle', '0-12': 'underline', '0-18': 'underline', '0-26': 'underline', '2-12': 'underline' },
        initialValue: {},
      },
      Layout: `
        <div style=" position: relative; font-size: 16px;">
          <div> <img src='img/FriendsPlus/Page23/E3/1.jpg' /> </div>
          <div style="position: absolute;top: 181px;left: 166px;width: 290px;">
            <div>I’m sitting on a <span style="padding: 1px; border: 2px solid #4285F4; border-radius: 50%;">train</span>, looking</div>
            <div> <input id='0' type='Circle' /> </div>
          </div>
          <div style="position: absolute;top: 24px;left: 605px;width: 290px">
            <div><input id='1' type='Circle' /></div>
          </div>
          <div style="position: absolute;top: 390px;left: 282px;width: 130px;">
            <div><input id='2' type='Circle' /></div>
          </div>
        </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P23-E4',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page23/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Circle the word that contains a different vowel sound.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#38C0E7' },
        limitSelect: 1,
        listWords: [
          "cake peas day rain", //0
          "eating seeing playing dreaming", //1
          "leaves key queen same", //2
          "feet tray space rain", //3
          "three please snake easy", //4
          "case race sea lake", //5
          "plane week mean sea", //6
        ],
        answers: ['0-2', '1-4', '2-6', '3-0', '4-4', '5-4', '6-0'],
        // initialValue: ['0-2', '1-4', '2-6', '3-0', '4-4', '5-4', '6-0'],
        initialValue: [],
      },
      Layout: `
      <div style='line-height: 48px; word-spacing: 20px;'>
        <div><b>1</b> train tray <span style=" border: solid 2px #38C0E7; border-radius: 50%; padding: 5px; ">tree</span> play</div>
        <div><b>2</b> <input id='0' type='Circle' /></div>
        <div><b>3</b> <input id='1' type='Circle' /></div>
        <div><b>4</b> <input id='2' type='Circle' /></div>
        <div><b>5</b> <input id='3' type='Circle' /></div>
        <div><b>6</b> <input id='4' type='Circle' /></div>
        <div><b>7</b> <input id='5' type='Circle' /></div>
        <div><b>8</b> <input id='6' type='Circle' /></div>
			</div>
      `,
    },
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P23-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page23/E5/1.jpg' },
      ],
    ],
  },
}

export default json;