import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {

  1: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P81-E1',
    audio: 'img/FriendsPlus/Page86/Audio/audio-e2-p86.mp3',
    video: 'Videos/e2.p86.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page86/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page86/E2/2.jpg', audioUrl: "img/FriendsPlus/Page86/Audio/tieude-e2-p86.mp3" },
      //   { url: 'img/FriendsPlus/Page86/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page86/E2/4.jpg', audioUrl: "img/FriendsPlus/Page86/Audio/hinh1-e2-p86.mp3" },
        { url: 'img/FriendsPlus/Page86/E2/5.jpg', audioUrl: "img/FriendsPlus/Page86/Audio/hinh2-e2-p86.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page86/E2/6.jpg', audioUrl: "img/FriendsPlus/Page86/Audio/hinh3-e2-p86.mp3" },
        { url: 'img/FriendsPlus/Page86/E2/7.jpg', audioUrl: "img/FriendsPlus/Page86/Audio/hinh4-e2-p86.mp3" },
      ],
    ],
  },

  2: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P87-E2',
    audio: 'img/FriendsPlus/Page87/Audio/audio-e2-p87.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page87/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/2.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/tieude-e2-p87.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page87/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page87/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/6.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/3 câu nhỏ bên phải.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page87/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/9.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/cau1.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/11.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/3 câu nhỏ bên phải.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page87/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/14.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/cau2.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/16.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/3 câu nhỏ bên phải.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page87/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/19.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/cau3.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/21.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/3 câu nhỏ bên phải.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/22.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page87/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page87/E2/24.jpg', audioUrl: "img/FriendsPlus/Page87/Audio/cau3.mp3" },
        { url: 'img/FriendsPlus/Page87/E2/25.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page87/E2/26.jpg' },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P87-E3',
    audio: '',
    video: '',
    component: D1,
    fontSize: 23,
    padding: 0,
    textAlign: 'center',
    //maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page87/E3/Key/answerKey.png',
    questionImage: [ // Row

      [
        // Column1
        { url: 'img/FriendsPlus/Page87/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page87/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page87/E3/3.jpg', input: true, answer: "don't you have a bowling party" },
        { url: 'img/FriendsPlus/Page87/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page87/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page87/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page87/E3/7.jpg', input: true, answer: "have fireworks." },
        { url: 'img/FriendsPlus/Page87/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page87/E3/9.jpg', input: true, answer: "about blowing out the candles" },
        { url: 'img/FriendsPlus/Page87/E3/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page87/E3/11.jpg' },
      ],



    ],
  },

  4: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P87-E4',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page87/E4/1.jpg' },

      ],
    ],
  },

  5: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P87-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page87/E5/1.jpg' },

      ],
    ],
  },


}

export default json;