import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P44-E1',
    audio: 'img/FriendsPlus/Page44/Audio/audio-e1-p44.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page44/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/2.jpg', audioUrl: "img/FriendsPlus/Page44/Audio/tieude-e1-p44.mp3" },
        { url: 'img/FriendsPlus/Page44/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page44/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page44/E1/5.jpg', audioUrl: "img/FriendsPlus/Page44/Audio/doan1.mp3" },
        { url: 'img/FriendsPlus/Page44/E1/6.jpg', audioUrl: "img/FriendsPlus/Page44/Audio/doan2.mp3" },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page44/E1/7.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P44-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page44/3.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P44-E3',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page44/E3/Key/answerKey.png',
    inputSize: 500,
    // titleImage: "img/FriendsPlus/Page44/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Now write sentences about the pictures.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" line-height: 58px; ">
            <div style='color: rgb(58,193,217);'> Khoa ran fast, but Huy ran slowly. </div>
            <div>#</div>
            <div>#</div>
          </div>
        `,
        answer: [
          "Lam played well, but Son played badly.",
          "Oanh sang loudly, but Thu sang quietly.",
        ],
      },
    ],
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P44-E4',
    audio: 'img/FriendsPlus/Page44/Audio/audio-e4-p44.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page44/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/2.jpg', audioUrl: "img/FriendsPlus/Page44/Audio/tieude-e4-p44.mp3" },
        { url: 'img/FriendsPlus/Page44/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page44/E4/4.jpg' },

      ],
    ],
  },
}

export default json;
