import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P35-E1',
    audio: 'img/FriendsPlus/Page35/Audio/audio-e1-p35.mp3',
    video: '',
    component: D1,
    padding: 0,
    maxLength: 1,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page35/E1/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/2.jpg', audioUrl: "img/FriendsPlus/Page35/Audio/tieude-e1-p35.mp3" },
        { url: 'img/FriendsPlus/Page35/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/6.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page35/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/8.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page35/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/10.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page35/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E1/12.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P35-E2',
    audio: 'img/FriendsPlus/Page35/Audio/audio-e1-p35.mp3',
    video: '',
    component: D1,
    padding: 0,
    maxLength: 1,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page35/E2/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/3.jpg', input: true, answer: "T" },
        { url: 'img/FriendsPlus/Page35/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/6.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page35/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/8.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page35/E2/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E2/10.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P35-E3',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    inputSize: 250,
    stylesTextInput: { border: 'none' },
    // titleImage: 'img/FriendsPlus/Page31/E4/1.jpg',
    // exerciseKey: 'img/FriendsPlus/Page31/E4/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Write notes about you. Then ask and answer with your friend.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" position: relative; line-height: 58px ">
            <div><img src='img/FriendsPlus/Page35/E3/1.jpg' /></div>
             <div style="position: absolute; top: 67px; left: 380px; display: inline-flex; justify-content: space-between; width: 530px; "># #</div>
             <div style="position: absolute; top: 116px; left: 380px; display: inline-flex; justify-content: space-between; width: 530px; "># #</div>
             <div style="position: absolute; top: 162px; left: 380px; display: inline-flex; justify-content: space-between; width: 530px; "># #</div>
             <div style="position: absolute; top: 209px; left: 380px; display: inline-flex; justify-content: space-between; width: 530px; "># #</div>
             <div style="position: absolute; top: 257px; left: 380px; display: inline-flex; justify-content: space-between; width: 530px; "># #</div>
          </div>
          `,
        answer: [
          "", "",
          "", "",
          "", "",
          "", "",
          "", "",
        ],
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P35-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page35/E4/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E4/3.jpg', input: true, answer: "clapping" },
        { url: 'img/FriendsPlus/Page35/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page35/E4/6.jpg', input: true, answer: "tapping" },
        { url: 'img/FriendsPlus/Page35/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page35/E4/9.jpg', input: true, answer: "running" },
        { url: 'img/FriendsPlus/Page35/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page35/E4/11.jpg' },
      ],
    ]
  },
  5: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P35-E5',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    inputSize: 800,
    titleImage: '',
    titleQuestion: [{ num: '5', title: 'Describe a festival in your town on<br/> another sheet of paper. Use the words from <big style="color:#445B9F">4</big> and your own ideas.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          `,
        answer: [
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ]
  },

}

export default json;