import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
	1: { // Exercise num
		unit: 'Unit 8',
		id: 'SB5-U8-P62-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [{ num: '1', title: 'Describe what is happpening in the picture', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page62/E1/1.jpg' },

			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 8',
		id: 'SB5-U8-P62-E2',
		audio: 'img/FriendsPlus/Page62/Audio/audio-e2-p62.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page62/E2/1.jpg' },
				{ url: 'img/FriendsPlus/Page62/E2/2.jpg', audioUrl: "img/FriendsPlus/Page62/Audio/tieude-e2-p62.mp3" },
				{ url: 'img/FriendsPlus/Page62/E2/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page62/E2/4.jpg' },
			],

		],
	},

	3: { // Exercise num
		unit: 'Unit 8',
		id: 'SB5-U8-P62-E3',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		// titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page62/E3/1.jpg' },

			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page62/E1/1.jpg' },

			],
		],
	},

	4: { // Exercise num
		unit: 'Unit 8',
		id: 'SB5-U8-P62-E4',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page62/E4/Key/answerKey.png',
		inputSize: 160,
		// titleImage: "img/FriendsPlus/Page62/E4/1.jpg",
		titleQuestion: [{ num: '4', title: 'Read again and complete the sentences.', color: "#2E479B" }],
		questionImage: [],
		questions: [
			{
				title: `
				<div style="display: flex;flex-direction: row;line-height: 58px;justify-content: space-between;width: 1100px;">
				<div>
					<div><b>1</b> The grasshopper played his <u style=" color:rgb(59,203,251); ">violin</u> .</div>
					<div><b>3</b> There is lots of food in the #.</div>
					
				</div>
				<div>
					<div><b>2</b> The ant carried some heavy #.</div>
					<div><b>4</b> That winter the ant was very #.</div>
				
				</div>
			</div>
				`,
				answer: [
					"summer",
					"corn",
					"kind",
				],
			},
		],
	},


}

export default json;