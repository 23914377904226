import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P70-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page70/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page70/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page70/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/3.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page70/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page70/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/6.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page70/E1/7.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page70/E1/8.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page70/E1/9.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page70/E1/10.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page70/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page70/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/13.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page70/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/15.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page70/E1/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page70/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/18.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page70/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/20.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page70/E1/21.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page70/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/23.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page70/E1/24.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page70/E1/25.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page70/E1/26.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page70/E1/27.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page70/E1/28.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page70/E1/29.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page70/E1/30.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page70/E1/31.jpg', },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page70/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/33.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page70/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/35.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page70/E1/36.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/37.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page70/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/39.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page70/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/41.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page70/E1/42.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page70/E1/43.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/44.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page70/E1/45.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/46.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page70/E1/47.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/48.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page70/E1/49.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page70/E1/50.jpg' },
      ],

    ],
  },

  2: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P70-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page70/E2/Key/answerKey.png',
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page70/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; ">
        <div>
          <div>Yesterday I was with my dad outside my house.</div>
          <div>A man asked, “Excuse me, where's the station?” </div>
          <div>My dad said, “Go <sup>1</sup> <u style='color: rgb(58,193,217);'>straight;</u>  , then turn <sup>2</sup> # .</div>
    
          <div> At the <sup>3</sup> # , turn <sup>4</sup> #</div>
    
          <div>and it's in front of you.” “Thank you,” said the man.</div>
          <div>“You know the city well.” “That's because I'm a taxi</div>
          <div> driver,” my dad said.</div>
        </div>

        <div><img src='img/FriendsPlus/Page70/E2/2.jpg'/></div>
      </div>
        `,
        answer: [
          "right",
          "traffic lights",
          "left"
        ],
      },
    ],
  },

  3: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P70-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page70/E3/Key/answerKey.png',
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page70/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        
        <div style=" display: flex; flex-direction: row; ">
          <div>
            <div>&ensp; <b>Hoa</b>  &ensp;<sup>1</sup><u style='color: rgb(58,193,217);'> Why  </u> are you smiling? </div>
            <div>&ensp; <b>Millie</b> <sup>2</sup># I'm happy. </div>
            <div>&ensp; <b>Hoa</b>  &ensp;<sup>3</sup># are you so happy?  </div>
            <div>&ensp; <b>Millie</b>  <sup>4</sup># I won this trophy.  </div>
            <div>&ensp; <b>Hoa</b>  &ensp;<sup>5</sup># did you win the trophy?  </div>
            <div>&ensp; <b>Millie</b>  I won it <sup>6</sup># I'm the fastest </div>
            <div>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; runner at school. <sup>7</sup># are you  &ensp;&ensp; &ensp;&ensp;</div>
            <div>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; are you asking so many questions?  </div>
                
          </div>

      
          <div><img src='img/FriendsPlus/Page70/E3/2.jpg'/></div>

        </div>
        
        `,
        answer: [
          "Because", "Why", "Because", "Why", "Because", "Why"


        ],
      },
    ],
  },


}

export default json;
