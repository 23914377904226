import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P21-E1',
    audio: 'img/FriendsPlus/Page20/Audio/audio-e2-p20.mp3',
    video: 'Videos/e2.p20.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page20/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page20/E2/2.jpg', audioUrl: "img/FriendsPlus/Page20/Audio/tieude-e2-p20.mp3" },
      //   { url: 'img/FriendsPlus/Page20/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page20/E2/4.jpg', audioUrl: "img/FriendsPlus/Page20/Audio/hinh1-e2-p20.mp3" },
        { url: 'img/FriendsPlus/Page20/E2/5.jpg', audioUrl: "img/FriendsPlus/Page20/Audio/hinh2-e2-p20.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page20/E2/6.jpg', audioUrl: "img/FriendsPlus/Page20/Audio/hinh3-e2-p20.mp3" },
        { url: 'img/FriendsPlus/Page20/E2/7.jpg', audioUrl: "img/FriendsPlus/Page20/Audio/hinh4-e2-p20.mp3" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P21-E2',
    audio: 'img/FriendsPlus/Page21/Audio/audio-e2-p21.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page21/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/2.jpg', audioUrl: "img/FriendsPlus/Page21/Audio/tieude-e2-p21.mp3" },
        { url: 'img/FriendsPlus/Page21/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page21/E2/4.jpg', audioUrl: "img/FriendsPlus/Page21/Audio/doan1.mp3" },
        { url: 'img/FriendsPlus/Page21/E2/5.jpg', audioUrl: "img/FriendsPlus/Page21/Audio/doan2.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page21/E2/6.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P21-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page21/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read, circle, and say.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "sit / is_sitting / sits", //0
          "sit / are_sitting / sits", //1
          "have / is_having / has", //2
          "have / is_having / has", //3
        ],
        answers: ['0-4', '1-0', '2-8', '3-4'],
        // initialValue: ['0-4', '1-0', '2-8', '3-4'],
        initialValue: [],
      },
      Layout: `
      <div style=" display: flex; flex-direction: row; line-height: 58px; ">
        <div>
          <div><b>1</b> She  <b>drink / <span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">is drinking</span> / drinks</b> a glass of water now.</div>
          <div><b>2</b> The family <b><input id='0' type='Circle' /></b> by the door today.</div>
          <div><b>3</b> They usually <b><input id='1' type='Circle' /></b> by the window.</div>
          <div><b>4</b> He never <b><input id='2' type='Circle' /></b> soup for dinner.</div>
          <div><b>5</b> She <b><input id='3' type='Circle' /></b> soup today.</div>
        </div>
        <div> <img src='img/FriendsPlus/Page21/E3/1.jpg' /> </div>
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P21-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page21/E4/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page21/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page21/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page21/E4/3.jpg', input: true, answer: "playing" },
        { url: 'img/FriendsPlus/Page21/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page21/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page21/E4/6.jpg', input: true, answer: "having" },
        { url: 'img/FriendsPlus/Page21/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page21/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page21/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page21/E4/10.jpg', input: true, answer: "have" },
        { url: 'img/FriendsPlus/Page21/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page21/E4/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page21/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page21/E4/14.jpg', input: true, answer: "having" },
        { url: 'img/FriendsPlus/Page21/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page21/E4/16.jpg', input: true, answer: "has" },
        { url: 'img/FriendsPlus/Page21/E4/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page21/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page21/E4/19.jpg', input: true, answer: "shares" },
        { url: 'img/FriendsPlus/Page21/E4/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page21/E4/21.jpg' },
      ],
    ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P21-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page21/E5/1.jpg' },
      ],
    ],
  },
}

export default json;