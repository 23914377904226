import UI from '../../components/ExcerciseTypes/Design/UserInterface';


const json = {
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P29-E3',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E3/2.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P29-E4',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E3/2.jpg' },
      ],
    ],
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P29-E5',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/4.jpg' },
      ],
      
    ],
  },
  6: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P29-E6',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E6/1.jpg' },
      ],
    ],
  },


}

export default json;