import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
	1: { // Exercise num
		unit: 'Unit 1',
		id: 'WB5-U1-P10-E1',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		checkUppercase: true,
		selectStyle: { color: '#00ADFE', width: 100, textAlign: 'center' },
		selectOptionValues: ['✓', '✗'],
		exerciseKey: 'img/FriendsPlus/Page10/E1/Key/answerKey.png',
		// titleImage: "img/FriendsPlus/Page10/E1/1.jpg",
		titleQuestion: [{ num: '1', title: 'Look and say. Write ✓ or ✗.', color: "#445B9F" }],
		questionImage: [],
		questions: [
			{
				title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page10/E1/1.jpg' /> </div>
						<div style=" position: absolute; top: 270px; left: 330px; "><select id='0'></select></div>
						<div style=" position: absolute; top: 270px; left: 480px; "><select id='1'></select></div>
						<div style=" position: absolute; top: 270px; left: 630px; "><select id='2'></select></div>
						<div style=" position: absolute; top: 270px; left: 780px; "><select id='3'></select></div>
						<div style=" position: absolute; top: 335px; left: 180px; "><select id='4'></select></div>
						<div style=" position: absolute; top: 335px; left: 330px; "><select id='5'></select></div>
						<div style=" position: absolute; top: 335px; left: 480px; "><select id='6'></select></div>
						<div style=" position: absolute; top: 335px; left: 630px; "><select id='7'></select></div>
						<div style=" position: absolute; top: 335px; left: 780px; "><select id='8'></select></div> 
					</div>
				`,
				answer: [
					"✓",
					"✓",
					"✓",
					"✓",
					"✓",
					"✓",
					"✓",
					"✓",
					"✓",
				],
			},
		],
	},
	2: { // Exercise num
		unit: 'Starter',
		id: 'SB5-U1-P10-E2',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		inputSize: 600,
		titleQuestion: [{ num: '2', title: 'Write four sentences about you.', color: "#445B9F" }],
		questionImage: [],
		questions: [
			{
				title:
					`
          <div style='color:rgb(82,197,235)'>I watched TV yesterday. I didn’t play soccer yesterday. I ...</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          `,
				answer: [
					"",
				],
			},
		]
	},
	3: { // Exercise num
		unit: 'Unit 1',
		id: 'SB5-U1-P10-E3',
		audio: 'img/FriendsPlus/Page10/Audio/audio-e3-p10.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page10/E3/1.jpg' },
				{ url: 'img/FriendsPlus/Page10/E3/2.jpg', audioUrl: "img/FriendsPlus/Page10/Audio/tieude-e3-p10.mp3" },
				{ url: 'img/FriendsPlus/Page10/E3/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page10/E3/4.jpg' },
			],

		],
	},
	4: { // Exercise num
		unit: 'Unit 1',
		id: 'SB5-U1-P10-E4',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page10/E4/1.jpg' },
			],
		],
	},
}

export default json;