import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
	1: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P54-E1',
		audio: 'img/FriendsPlus/Page54/Audio/audio-e1-p54.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row

			[
				// Column1
				{ url: 'img/FriendsPlus/Page54/E1/1.jpg' },
				{ url: 'img/FriendsPlus/Page54/E1/2.jpg', audioUrl: "img/FriendsPlus/Page54/Audio/tieude-e1-p54.mp3" },
				{ url: 'img/FriendsPlus/Page54/E1/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page54/E1/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page54/E1/5.jpg', audioUrl: "img/FriendsPlus/Page54/Audio/cau1.mp3" },
				{ url: 'img/FriendsPlus/Page54/E1/6.jpg', audioUrl: "img/FriendsPlus/Page54/Audio/cau2.mp3" },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page54/E1/7.jpg', audioUrl: "img/FriendsPlus/Page54/Audio/cau3.mp3" },
				{ url: 'img/FriendsPlus/Page54/E1/8.jpg', audioUrl: "img/FriendsPlus/Page54/Audio/cau4.mp3" },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page54/E1/9.jpg' },
			],

		],
	},
	2: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P54-E2',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page54/E2/1.jpg' },

			],
		],
	},

	3: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P54-E3',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		exerciseKey: 'img/FriendsPlus/Page54/E3/Key/answerKey.png',
		inputSize: 1200,
		// titleImage: "img/FriendsPlus/Page7/E3/1.jpg",
		titleQuestion: [{ num: '3', title: 'Now write sentences about the pictures.', color: "#2E479B" }],
		questionImage: [],
		questions: [
			{
				title: `
				<div style=" line-height: 58px; ">
					<div style='color: rgb(58,193,217);'>The woman and her son were late because they were lost.</div>			
					<div>#</div>
					<div>#</div>
					<div>#</div>
				</div>
				`,
				answer: [
					"They were lost because they couldn't read the map.",
					"They couldn't read the map because it got wet. ",
					"The map got wet because they forgot the umbrella. They forgot the umbrella because they were late!"
				],
			},
		],
	},

	4: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P54-E4',
		audio: 'img/FriendsPlus/Page54/Audio/audio-e4-p54.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page54/E4/1.jpg' },
				{ url: 'img/FriendsPlus/Page54/E4/2.jpg', audioUrl: "img/FriendsPlus/Page54/Audio/tieude-e4-p54.mp3" },
				{ url: 'img/FriendsPlus/Page54/E4/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page54/E4/4.jpg' },

			],
		],
	},
}

export default json;