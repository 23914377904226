import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

import DrawColor from '../../components/ExcerciseTypes/DrawColor';

const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P77-E1',
    audio: 'img/FriendsPlus/Page77/Audio/audio-e1-p77.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page77/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/2.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/tieude-e1-p77.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page77/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page77/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/6.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/ed say t.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/8.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/ed say id.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/10.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/ed say d.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page77/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/13.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/walked.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/15.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/waited.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/17.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/showed.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/18.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page77/E1/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page77/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/21.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/finished,washed.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/23.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/painted,started.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/25.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/played,rained.mp3" },
        { url: 'img/FriendsPlus/Page77/E1/26.jpg' },
      ],


    ],
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P77-E2',
    audio: 'img/FriendsPlus/Page77/Audio/audio-e2-p77.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page77/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/2.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/tieude-e2-p77.mp3" },
        { url: 'img/FriendsPlus/Page77/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page77/E2/4.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/hinh1-e2-p77.mp3" },
        { url: 'img/FriendsPlus/Page77/E2/5.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/hinh2-e2-p77.mp3" },

      ],


    ],
  },

  3: {

    unit: 'Unit 10',
    id: 'SB5-U10-P77-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page77/E3/Key/answerKey.png',
    titleQuestion: [{
      num: '3', title: 'Read again. Circle the <i>ed</i> words in blue when they say <i>t</i>, in green when they <br> say <i>id</i>, and in purple when they say <i>d</i>', color: "#394893"
    }],

    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: { padding: 2 },
          green: { padding: 0.5, border: 'solid 2px', borderRadius: '50%', borderColor: 'green' },
          blue: { padding: 0.5, border: 'solid 2px', borderRadius: '50%', borderColor: 'blue' },
          purple: { padding: 0.5, border: 'solid 2px', borderRadius: '50%', borderColor: 'purple' },
          // underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          "Alice walked home from school yesterday.",
          "She got wet because it rained. When Alice",
          "finished her homework, she painted a ",
          "picture and showed it to her mom.",

          "We waited until the baseball game",
          "started. The teams played until ten",
          "thirty."
        ],
        answers: { '0-2': 'blue', '2-0': 'blue', '2-8': 'green', '1-10': 'purple', '3-4': 'purple', '4-2': 'green', '5-0': 'green', '5-6': 'purple' },
        initialValue: {},
      },
      Layout: `
			   

      <div style=" position: relative; font-size: 18px;"> 
        <div><img src='img/FriendsPlus/Page77/E3/1.jpg' /></div>
        <div style="position: absolute;top: 245px;left: 40px; ">
          <div><span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">Paul’s</span> <input id='0' type='Circle' /></div>
          <div><input id='1' type='Circle' /></div>
          <div><input id='2' type='Circle' /></div>
          <div><input id='3' type='Circle' /></div>
        </div>
        <div style="position: absolute;top: 270px;left: 545px;">
          <div><input id='4' type='Circle' /></div>
          <div><input id='5' type='Circle' /></div>
          <div><input id='6' type='Circle' /></div>
        </div>
      </div>
			    `,
    },
  },




  4: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P77-E4',
    audio: 'img/FriendsPlus/Page77/Audio/audio-e4-p77.mp3',
    video: '',
    component: D1,
    checkDuplicated: true,
    padding: 0,
    textAlign: 'center',
    //maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page77/E4/Key/answerKey.png',



    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page77/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/2.jpg', audioUrl: "img/FriendsPlus/Page77/Audio/tieude-e4-p77.mp3" },
        { url: 'img/FriendsPlus/Page77/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page77/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page77/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/6.jpg', input: true, answer: "washed/walked/finished" },
        { url: 'img/FriendsPlus/Page77/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/8.jpg', input: true, answer: "played/showed/rained" },
        { url: 'img/FriendsPlus/Page77/E4/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page77/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page77/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/12.jpg', input: true, answer: "walked/finished/washed" },
        { url: 'img/FriendsPlus/Page77/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/14.jpg', input: true, answer: "waited/started" },
        { url: 'img/FriendsPlus/Page77/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/16.jpg', input: true, answer: "showed/rained/played" },
        { url: 'img/FriendsPlus/Page77/E4/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page77/E4/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page77/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/20.jpg', input: true, answer: "finished/washed/walked" },
        { url: 'img/FriendsPlus/Page77/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/22.jpg', input: true, answer: "started/waited" },
        { url: 'img/FriendsPlus/Page77/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page77/E4/24.jpg', input: true, answer: "rained/played/showed" },
        { url: 'img/FriendsPlus/Page77/E4/25.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page77/E4/26.jpg' },
      ],

    ],
  },

  5: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P77-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page77/E5/1.jpg' },

      ],
    ],
  },

}

export default json;