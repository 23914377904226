import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P41-E1',
    audio: 'img/FriendsPlus/Page41/Audio/audio-e1-p41.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page41/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page41/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/2.jpg', audioUrl: "img/FriendsPlus/Page41/Audio/tieude-e1-p41.mp3" },
        { url: 'img/FriendsPlus/Page41/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page41/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page41/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/6.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page41/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/8.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page41/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/10.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page41/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page41/E1/12.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P41-E2',
    audio: 'img/FriendsPlus/Page41/Audio/audio-e1-p41.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: 'img/FriendsPlus/Page41/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page41/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page41/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page41/E2/4.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page41/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/6.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page41/E2/7.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page41/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/9.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page41/E2/10.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page41/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/12.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page41/E2/13.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page41/E2/14.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P41-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page41/E3/1.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P41-E4',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page41/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page41/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page41/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page41/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/8.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page41/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/11.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/13.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page41/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/16.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page41/E4/20.jpg' },
      ],
    ],
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P41-E5',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page41/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page41/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page41/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page41/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/8.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page41/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/11.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/13.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page41/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/16.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page41/E4/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page41/E4/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page41/E4/20.jpg' },
      ],
    ],
  },

}

export default json;
