import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P5-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E1/1.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P5-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page5/E2/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page5/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/3.jpg', input: true, answer: "can" },
        { url: 'img/FriendsPlus/Page5/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page5/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/6.jpg', input: true, answer: "can" },
        { url: 'img/FriendsPlus/Page5/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page5/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/9.jpg', input: true, answer: "can't" },
        { url: 'img/FriendsPlus/Page5/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page5/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page5/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/13.jpg', input: true, answer: "Can" },
        { url: 'img/FriendsPlus/Page5/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page5/E2/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page5/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/17.jpg', input: true, answer: "can't" },
        { url: 'img/FriendsPlus/Page5/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/19.jpg', input: true, answer: "can" },
        { url: 'img/FriendsPlus/Page5/E2/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page5/E2/21.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P5-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E3/1.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Starter',
    id: 'SB5-S-P5-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    inputSize: 500,
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Now write about what your friend would like.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style='color:rgb(82,197,235)'>Xuan would like ...</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          `,
        answer: [
          "",
          "",
          "",
        ],
      },
    ]
  },
}

export default json;