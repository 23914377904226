import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
	1: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P56-E1',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		titleQuestion: [{ num: '1', title: 'What do you know about water puppet theater?', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column2
				{ url: 'img/FriendsPlus/Page56/E1/1.jpg' },

			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P56-E2',
		audio: 'img/FriendsPlus/Page56/Audio/audio-e2-p56.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row

			[
				// Column1
				{ url: 'img/FriendsPlus/Page56/E2/1.jpg' },
				{ url: 'img/FriendsPlus/Page56/E2/2.jpg', audioUrl: "img/FriendsPlus/Page56/Audio/tieude-e2-p56.mp3" },
				{ url: 'img/FriendsPlus/Page56/E2/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page56/E2/4.jpg' },
			],

		],
	},
	3: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P56-E3',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		// titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page56/E3/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page56/E2/4.jpg' },
			],
		],
	},

	4: { // Exercise num
		unit: 'Unit 7',
		id: 'SB5-U7-P56-E4',
		audio: '',
		video: '',
		component: D1,
		padding: 0,
		textAlign: 'center',
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page56/E4/Key/answerKey.png',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page56/E4/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page56/E4/2.jpg' },
				{ url: 'img/FriendsPlus/Page56/E4/3.jpg', input: true, answer: "F" },
				{ url: 'img/FriendsPlus/Page56/E4/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page56/E4/5.jpg' },
				{ url: 'img/FriendsPlus/Page56/E4/6.jpg', input: true, answer: "T" },
				{ url: 'img/FriendsPlus/Page56/E4/7.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page56/E4/8.jpg' },
				{ url: 'img/FriendsPlus/Page56/E4/9.jpg', input: true, answer: "F" },
				{ url: 'img/FriendsPlus/Page56/E4/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page56/E4/11.jpg' },
				{ url: 'img/FriendsPlus/Page56/E4/12.jpg', input: true, answer: "T" },
				{ url: 'img/FriendsPlus/Page56/E4/13.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page56/E4/14.jpg' },
			],

		],
	},

}

export default json;