import UI from '../../components/ExcerciseTypes/Design/UserInterface';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P30-E1',
    audio: 'img/FriendsPlus/Page30/Audio/audio-e1-p30.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/2.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/tieude-e1-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/5.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh1-e1-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E1/6.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh2-e1-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E1/7.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh3-e1-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E1/8.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh4-e1-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E1/9.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page30/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/11.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh5-e1-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E1/12.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh6-e1-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E1/13.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh7-e1-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E1/14.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh8-e1-p30.mp3" },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P30-E2',
    audio: 'img/FriendsPlus/Page30/Audio/audio-e2-p30.mp3',
    video: 'Videos/e2.p30.mp4',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/2.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/tieude-e2-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E2/4.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh1-e2-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E2/5.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh2-e2-p30.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page30/E2/6.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh3-e2-p30.mp3" },
        { url: 'img/FriendsPlus/Page30/E2/7.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/hinh4-e2-p30.mp3" },
      ],

    ],
  },


}

export default json;