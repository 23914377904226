import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';



const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB5-U2-P17-E1',
    audio: 'img/FriendsPlus/Page17/Audio/audio-e1-p17.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page17/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/2.jpg', audioUrl: "img/FriendsPlus/Page17/Audio/tieude-e1-p17.mp3" },
        { url: 'img/FriendsPlus/Page17/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page17/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/5.jpg', audioUrl: "img/FriendsPlus/Page17/Audio/book.mp3" },
        { url: 'img/FriendsPlus/Page17/E1/6.jpg', audioUrl: "img/FriendsPlus/Page17/Audio/wool.mp3" },
        { url: 'img/FriendsPlus/Page17/E1/7.jpg', audioUrl: "img/FriendsPlus/Page17/Audio/wood.mp3" },
        { url: 'img/FriendsPlus/Page17/E1/8.jpg', audioUrl: "img/FriendsPlus/Page17/Audio/hood.mp3" },
        { url: 'img/FriendsPlus/Page17/E1/9.jpg', audioUrl: "img/FriendsPlus/Page17/Audio/cook.mp3" },
        { url: 'img/FriendsPlus/Page17/E1/10.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB5-U2-P17-E2',
    audio: 'img/FriendsPlus/Page17/Audio/audio-e2-p17.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page17/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/2.jpg', audioUrl: "img/FriendsPlus/Page17/Audio/tieude-e2-p17.mp3" },
        { url: 'img/FriendsPlus/Page17/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page17/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB5-U2-P17-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page17/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read the chant again. Circle the words with <i>oo</i>.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, border: 'solid 2px', borderColor: '#2CBAEA' },
        // limitSelect: 2,
        listWords: [
          "It’s a very cold day.", //0
          "I have a coat with", //1
          // "a hood.", //
          "I have my wool scarf,", //2
          "And I’m feeling good.", //3

          "I’m sitting on some", //4
          "wood.", //5
          "I’m looking at my book.", //6
          "The book’s very good.", //7
          "It tells me how to cook.", //8
        ],
        answers: ['2-6', '3-6', '5-0', '6-8', '7-2', '7-6', '8-10'],
        // initialValue: ['2-6', '3-6', '5-0', '6-8', '7-2', '7-6', '8-10'],
        initialValue: [],
      },
      Layout: `
      <div style=" position: relative; width:934px; text-align: center;">
        <div> <img src='img/FriendsPlus/Page17/E3/1.jpg' /> </div>
        <div style="position: absolute;top: 69px;left: 350px;">
          <div><input id='0' type='Circle' /></div>
          <div><input id='1' type='Circle' /></div>
          <div>a <span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 3px;">hood.</span></div>
          <div><input id='2' type='Circle' /></div>
          <div><input id='3' type='Circle' /></div>
        </div>
        <div style="position: absolute;top: 69px;left: 630px;">
          <div><input id='4' type='Circle' /></div>
          <div><input id='5' type='Circle' /></div>
          <div><input id='6' type='Circle' /></div>
          <div><input id='7' type='Circle' /></div>
          <div><input id='8' type='Circle' /></div>
        </div>
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB5-U1-P17-E4',
    audio: 'img/FriendsPlus/Page17/Audio/audio-e4-p17.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page17/E4/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page17/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/2.jpg', audioUrl: "img/FriendsPlus/Page17/Audio/tieude-e4p17.mp3" },
        { url: 'img/FriendsPlus/Page17/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page17/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page17/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/6.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page17/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/8.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page17/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/10.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page17/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/12.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page17/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/14.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page17/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/16.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page17/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/18.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page17/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page17/E4/20.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page17/E4/21.jpg' },
      ],

    ]
  },
  5: { // Exercise num
    unit: 'Unit 2',
    id: 'SB5-U1-P17-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page17/E5/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page17/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page17/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page17/E5/3.jpg', input: true, answer: "cook" },
        { url: 'img/FriendsPlus/Page17/E5/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page17/E5/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page17/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page17/E5/7.jpg', input: true, answer: "hood" },
        { url: 'img/FriendsPlus/Page17/E5/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page17/E5/9.jpg' },
      ],
    ]
  },
  6: { // Exercise num
    unit: 'Unit 2',
    id: 'SB5-U2-P17-E6',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page17/E6/1.jpg' },
      ],
    ],
  },

}

export default json;