import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';

const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P45-E1',
    audio: 'img/FriendsPlus/Page45/Audio/audio-e1-p45.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page45/E1/2.jpg', audioUrl: "img/FriendsPlus/Page45/Audio/tieude-e1-p45.mp3" },
        { url: 'img/FriendsPlus/Page45/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page45/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page45/E1/5.jpg', audioUrl: "img/FriendsPlus/Page45/Audio/smell.mp3" },
        { url: 'img/FriendsPlus/Page45/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page45/E1/7.jpg', audioUrl: "img/FriendsPlus/Page45/Audio/mirror.mp3" },
        { url: 'img/FriendsPlus/Page45/E1/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page45/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page45/E1/10.jpg', audioUrl: "img/FriendsPlus/Page45/Audio/bell.mp3" },
        { url: 'img/FriendsPlus/Page45/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page45/E1/12.jpg', audioUrl: "img/FriendsPlus/Page45/Audio/carrot.mp3" },
        { url: 'img/FriendsPlus/Page45/E1/13.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page45/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page45/E1/15.jpg', audioUrl: "img/FriendsPlus/Page45/Audio/shell.mp3" },
        { url: 'img/FriendsPlus/Page45/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page45/E1/17.jpg', audioUrl: "img/FriendsPlus/Page45/Audio/cherry.mp3" },
        { url: 'img/FriendsPlus/Page45/E1/18.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page45/E1/19.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P45-E2',
    audio: 'img/FriendsPlus/Page45/Audio/audio-e2-p45.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/2.jpg', audioUrl: "img/FriendsPlus/Page45/Audio/tieude-e2-p45.mp3" },
        { url: 'img/FriendsPlus/Page45/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page45/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P45-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page45/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read again. Circle the words with <i>ll</i> and underline the words with <i>rr</i>.', color: "#2E479B" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: {},
          circle: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
          underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          "I’m going to the beach with my friend today!",	//0
          "I’m going to take my doll. Her name is Carrie.",	//1
          "We have a picnic: sandwiches, cheese, carrots, and",	//2
          "cherries. We’re going to look for shells and smell",	//3
          "the ocean air. Oh! I can hear the doorbell.",	//4
          "My friend is here.",	//5
        ],
        answers: { '1-10': 'circle', '3-12': 'circle', '3-16': 'circle', '4-16': 'circle', '1-18': 'underline', '3-0': 'underline', '2-12': 'underline' },
        initialValue: {},
      },
      Layout: `
      <div style=" display: flex; flex-direction: row; line-height: 48px; ">
        <div>
          <div>I’m waiting at the door, looking in the <span style=" border-bottom: 2px solid rgb(66, 133, 244);">mirror</span>, and</div>
          <div>brushing my hair.</div>
          <div><input id='0' type='Circle' /></div>
          <div><input id='1' type='Circle' /></div>
          <div><input id='2' type='Circle' /></div>
          <div><input id='3' type='Circle' /></div>
          <div><input id='4' type='Circle' /></div>
          <div><input id='5' type='Circle' /></div>
        </div>
        <div> <img src='img/FriendsPlus/Page45/E3/1.jpg' /> </div>
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P45-E4',
    audio: '',
    video: '',
    component: D1,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page45/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page45/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page45/E4/3.jpg', input: true, answer: "bell" },
        { url: 'img/FriendsPlus/Page45/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page45/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page45/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page45/E4/7.jpg', input: true, answer: "mirror" },
        { url: 'img/FriendsPlus/Page45/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page45/E4/9.jpg', input: true, answer: "carrot" },
        { url: 'img/FriendsPlus/Page45/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page45/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page45/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page45/E4/13.jpg', input: true, answer: "cherry" },
        { url: 'img/FriendsPlus/Page45/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page45/E4/15.jpg', input: true, answer: "smell" },
        { url: 'img/FriendsPlus/Page45/E4/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page45/E4/17.jpg' },
      ],

    ],
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P45-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E5/1.jpg' },
      ],
    ],
  },
}

export default json;