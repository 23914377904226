import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P26-E1',
    audio: '',
    video: '',
    component: D1,
    maxLength: 1,
    padding: 0,
    textAlign: 'center',
    inputHeight: 40,
    exerciseKey: 'img/FriendsPlus/Page26/E1/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page26/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page26/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page26/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/7.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page26/E1/8.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page26/E1/9.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page26/E1/10.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page26/E1/11.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page26/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/13.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page26/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/15.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page26/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/19.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page26/E1/20.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page26/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/24.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page26/E1/25.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page26/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/33.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page26/E1/34.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page26/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/36.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page26/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/39.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/40.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page26/E1/41.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/43.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/44.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page26/E1/45.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page26/E1/46.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/47.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/48.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page26/E1/49.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page26/E1/50.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page26/E1/51.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page26/E1/52.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page26/E1/53.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page26/E1/54.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/55.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page26/E1/56.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/57.jpg', input: true, answer: "y" },
        { url: 'img/FriendsPlus/Page26/E1/58.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/59.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page26/E1/60.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/61.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page26/E1/62.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/63.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/64.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page26/E1/65.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/66.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page26/E1/67.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/68.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/69.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page26/E1/70.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/71.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/72.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page26/E1/73.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page26/E1/74.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page26/E1/75.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page26/E1/76.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P26-E2',
    audio: '',
    video: '',
    component: T6,
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page26/E2/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page26/E2/1.jpg',
    titleQuestion: [{ num: '2', title: 'Write the correct words.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div>Holly is going to go to Florida on vacation. She’s putting all her things in her <sup>1</sup> <u style='color: rgb(58,193,217);'>suitcase</u>.</div>
          <div>She’s going to take her <sup>2</sup> # to use after swimming. She’s going to take her</div>
          <div><sup>3</sup> # and <sup>4</sup> # to brush her teeth. She’s going to take some</div>
          <div><sup>5</sup> # to wash her hair. She’s going to take some <sup>6</sup> # because the</div>
          <div>sun is very hot in Florida.</div>
          `,
        answer: [
          "towel",
          "toothpaste",
          "toothbrush",
          "shampoo",
          "sunscreen",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P26-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page26/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Look and read. Circle.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "played / didn’t_play",	//0
          "cooked / didn’t_cook",	//1
          "played / didn’t_play",	//2
          "watched / didn’t_watch",	//3
          "listened / didn’t",	//4
        ],
        answers: ['0-4', '1-0', '2-0', '3-0', '4-0'],
        initialValue: [],
      },
      Layout: `
      <div style=" display: flex; flex-direction: row; line-height: 48px; ">
        <div>
          <div><b>1</b> Mom <b>listened /</b> <b style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">didn’t listen</b> to music</div>
          <div>&ensp; yesterday.</div>
          <div><b>2</b> The girls <b><input id='0' type='Circle' /></b> a game.</div>
          <div><b>3</b> Dad <b><input id='1' type='Circle' /></b> dinner.</div>
          <div><b>4</b> The boys <b><input id='2' type='Circle' /></b> a game.</div>
          <div><b>5</b> Mom <b><input id='3' type='Circle' /></b> TV.</div>
          <div><b>6</b> The girls <b><input id='4' type='Circle' /></b> listen to music.</div>
        </div>
        <div> <img src='img/FriendsPlus/Page26/E3/1.jpg' /> </div>
      </div>
      `,
    },
  },


}

export default json;