import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P22-E1',
    audio: 'img/FriendsPlus/Page22/Audio/audio-e1-p22.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/2.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/tieude-e1-p22.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page22/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/6.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/always.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/8.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/now.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/9.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/right now.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/10.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page22/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/12.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/usually.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/14.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/today.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/15.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/at the moment.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page22/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/18.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/sometimes.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page22/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/21.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/reraly.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/23.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/He...pizza now.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/24.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page22/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/26.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/never.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/28.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/He...pizza now.mp3" },
        { url: 'img/FriendsPlus/Page22/E1/29.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page22/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/31.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P22-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/3.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P22-E3',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    inputSize: 500,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page22/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Now write sentences about the girls.', color: "#445B9F" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          `,
        answer: [
          "Chi is having a bowl of soup now.",
          "Chi sometimes has a bowl of noodles.",
          "An usually has a bowl of salad.",
          "An is having a bowl of rice right now.",
          "Mai is having a bowl of soup today.",
          "Mai sometimes has a bowl of rice.",
          "Thu always has a bowl of salad.",
          "Thu is having a bowl of noodles today.",
        ],
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P22-E4',
    audio: 'img/FriendsPlus/Page22/Audio/audio-e4-p22.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page22/E4/2.jpg', audioUrl: "img/FriendsPlus/Page22/Audio/tieude-e4-p22.mp3" },
        { url: 'img/FriendsPlus/Page22/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E4/4.jpg' },
      ],

    ],
  },
}

export default json;