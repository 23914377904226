import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P33-E1',
    audio: 'img/FriendsPlus/Page33/Audio/audio-e1-p33.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page33/E1/2.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/tieude-e1-p33.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E1/4.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/long i.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page33/E1/6.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/long o.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/7.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page33/E1/8.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/light.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/9.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/cry.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/10.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/bike.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/11.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/boat.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/12.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/blow.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/13.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/bone.mp3" },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page33/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page33/E1/15.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/long u.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page33/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page33/E1/18.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/room.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/19.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/blue.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/20.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/flute.mp3" },
        { url: 'img/FriendsPlus/Page33/E1/21.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P33-E2',
    audio: 'img/FriendsPlus/Page33/Audio/audio-e2-p33.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/2.jpg', audioUrl: "img/FriendsPlus/Page33/Audio/tieude-e2-p33.mp3" },
        { url: 'img/FriendsPlus/Page33/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P33-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page33/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read again. Circle the words with long <i>i</i> sounds in blue,<br/> the words with long <i>o</i> sounds in green, and the words<br/> with long <i>u</i> sounds in purple.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        // limitSelect: 1,
        listWords: [
          "I’m out in my boat.", //0
          "The wind is blowing", //1
          "And I’m wearing a coat.", //2
          "The sky’s dark blue, I can see the moon.", //3
          "I can see a bright light", //4
          "From a distant room.", //5
        ],
        answers: ['3-2', '4-8', '4-10', '0-8', '1-6', '2-8', '3-6', '3-16', '5-6'],
        // initialValue: ['3-2', '4-8', '4-10', '0-8', '1-6', '2-8', '3-6', '3-16', '5-6'],
        initialValue: [],
      },
      Layout: `
      <div style=" display: flex; flex-direction: row; line-height: 48px; ">
        <div>
          <div> It’s a cold, cold <span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">night,</span> and <input id='0' type='Circle' /></div>
          <div><input id='1' type='Circle' /></div>
          <div><input id='2' type='Circle' /></div>
          <div><input id='3' type='Circle' /></div>
          <div><input id='4' type='Circle' /></div>
          <div><input id='5' type='Circle' /></div>
        </div>
        <div> <img src='img/FriendsPlus/Page33/E3/1.jpg' /> </div>
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P33-E4',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page33/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Circle the word that contains a different vowel sound.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "blue flute boot sky", //0
          "my fly blow high", //1
          "moon bone elbow snow", //2
          "dry boat like night", //3
          "room June soon stone", //4
        ],
        answers: ['2-0', '0-6', '3-2', '1-4', '4-6'],
        // initialValue: ['2-0', '0-6', '3-2', '1-4', '4-6'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;line-height: 48px;justify-content: space-between;width: 700px; word-spacing: 10px;">
        <div>
          <div><b>1</b> light smile <span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">soap</span> cry</div>
          <div><b>3</b> <input id='0' type='Circle' /> </div>
          <div><b>5</b> <input id='1' type='Circle' /> </div>
        </div>
        <div>
          <div><b>2</b> <input id='2' type='Circle' /> </div>
          <div><b>4</b> <input id='3' type='Circle' /> </div>
          <div><b>6</b> <input id='4' type='Circle' /> </div>
        </div>
      </div>
      `,
    },
  },
  5: { // Exercise num
    unit: 'Unit 4',
    id: 'SB5-U4-P33-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E5/1.jpg' },
      ],
    ],
  },
}

export default json;