import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
	1: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P15-E1',
		audio: 'img/FriendsPlus/Page14/Audio/audio-e2-p14.mp3',
		video: 'Videos/e2.p14.mp4',
		component: UI,
		recorder: true,
		titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
		questionImage: [ // Row
			// [
			// 	// Column1
			// 	{ url: 'img/FriendsPlus/Page14/E2/1.jpg' },
			// 	{ url: 'img/FriendsPlus/Page14/E2/2.jpg', audioUrl: "img/FriendsPlus/Page14/Audio/tieude-e2-p14.mp3" },
			// 	{ url: 'img/FriendsPlus/Page14/E2/3.jpg' },
			// ],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page14/E2/4.jpg', audioUrl: "img/FriendsPlus/Page14/Audio/hinh1-e2-p14.mp3" },
				{ url: 'img/FriendsPlus/Page14/E2/5.jpg', audioUrl: "img/FriendsPlus/Page14/Audio/hinh2-e2-p14.mp3" },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page14/E2/6.jpg', audioUrl: "img/FriendsPlus/Page14/Audio/hinh3-e2-p14.mp3" },
				{ url: 'img/FriendsPlus/Page14/E2/7.jpg', audioUrl: "img/FriendsPlus/Page14/Audio/hinh4-e2-p14.mp3" },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P15-E2',
		audio: 'img/FriendsPlus/Page15/Audio/audio-e2-p15.mp3',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page15/E2/1.jpg' },
				{ url: 'img/FriendsPlus/Page15/E2/2.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/tieude-e2-p15.mp3" },
				{ url: 'img/FriendsPlus/Page15/E2/3.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page15/E2/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page15/E2/5.jpg' },
				{ url: 'img/FriendsPlus/Page15/E2/6.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/doan1.mp3" },
				{ url: 'img/FriendsPlus/Page15/E2/7.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page15/E2/8.jpg' },
				{ url: 'img/FriendsPlus/Page15/E2/9.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/doan2.mp3" },
				{ url: 'img/FriendsPlus/Page15/E2/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page15/E2/11.jpg' },
				{ url: 'img/FriendsPlus/Page15/E2/12.jpg', audioUrl: "img/FriendsPlus/Page15/Audio/doan3.mp3" },
				{ url: 'img/FriendsPlus/Page15/E2/13.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page15/E2/14.jpg' },
			],

		],
	},

	3: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P15-E3',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page15/E3/Key/answerKey.png',
		titleQuestion: [{ num: '3', title: 'Read and circle.', color: "#394893" }],
		question: {
			Write: {
				inputStyle: { width: 70, },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 3, border: 'solid 2px', borderColor: '#2CBAEA' },
				limitSelect: 1,
				listWords: [
					"’m / ’re", //0
					"am / are", //1
					"is / are", //2
				],
				answers: ['0-0', '1-4', '2-0'],
				// initialValue: ['0-0', '1-4', '2-0'],
				initialValue: [],
			},
			Layout: `
      <div>
				<div><b>1</b> We <b>is / </b><b style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 3px; ">are</b> going to go on vacation next week.</div>
				<div><b>2</b> I <b><input id='0' type='Circle' /></b> going to take my shampoo on vacation.</div>
				<div><b>3</b> Mom and Dad <b><input id='1' type='Circle' /></b> going to buy soap and toothpaste tomorrow.</div>
				<div><b>4</b> Trung <b><input id='2' type='Circle' /></b> going to put sunscreen on when he goes to the beach.</div>
			</div>
      `,
		},
	},
	4: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P15-E4',
		audio: '',
		video: '',
		component: D1,
		padding: 0,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page15/E4/Key/answerKey.png',
		question: [
		],
		questionImage: [
			[
				// Column1
				{ url: 'img/FriendsPlus/Page15/E4/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page15/E4/2.jpg' },
				{ url: 'img/FriendsPlus/Page15/E4/3.jpg', input: true, answer: "'re" },
				{ url: 'img/FriendsPlus/Page15/E4/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page15/E4/5.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page15/E4/6.jpg' },
				{ url: 'img/FriendsPlus/Page15/E4/7.jpg', input: true, answer: "'m" },
				{ url: 'img/FriendsPlus/Page15/E4/8.jpg' },
				{ url: 'img/FriendsPlus/Page15/E4/9.jpg', input: true, answer: "'s" },
				{ url: 'img/FriendsPlus/Page15/E4/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page15/E4/11.jpg' },
			],
		]
	},
	5: { // Exercise num
		unit: 'Unit 2',
		id: 'SB5-U2-P15-E5',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page15/E5/1.jpg' },

			],
		],
	},

}

export default json;