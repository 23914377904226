import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';



const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P39-E1',
    audio: 'img/FriendsPlus/Page39/Audio/audio-e1-p39.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/2.jpg', audioUrl: "img/FriendsPlus/Page39/Audio/tieude-e1-p39.mp3" },
        { url: 'img/FriendsPlus/Page39/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page39/E1/5.jpg', audioUrl: "img/FriendsPlus/Page39/Audio/best.mp3" },
        { url: 'img/FriendsPlus/Page39/E1/6.jpg', audioUrl: "img/FriendsPlus/Page39/Audio/vest.mp3" },
        { url: 'img/FriendsPlus/Page39/E1/7.jpg', audioUrl: "img/FriendsPlus/Page39/Audio/vet.mp3" },
        { url: 'img/FriendsPlus/Page39/E1/8.jpg', audioUrl: "img/FriendsPlus/Page39/Audio/wet.mp3" },
        { url: 'img/FriendsPlus/Page39/E1/9.jpg', audioUrl: "img/FriendsPlus/Page39/Audio/pig.mp3" },
        { url: 'img/FriendsPlus/Page39/E1/10.jpg', audioUrl: "img/FriendsPlus/Page39/Audio/big.mp3" },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P39-E2',
    audio: 'img/FriendsPlus/Page39/Audio/audio-e2-p39.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/2.jpg', audioUrl: "img/FriendsPlus/Page39/Audio/tieude-e2-p39.mp3" },
        { url: 'img/FriendsPlus/Page39/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P39-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page39/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read again. Circle the words with the sounds from <b style="color: #394893">1</b>. Use red for <i>b</i>, pink for <i>v</i>,<br/> blue for <i>w</i>, and green for <i>p</i>.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: {},
          red: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'red' },
          pink: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'pink' },
          blue: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'blue' },
          green: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'green' },
          // underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          "a cold day, so I put on a warm vest", //0
          "and went out for a walk.",   //1
          "We saw some pigs. Some were big, but one",   //2
          "was really little. I liked the little one best.",  //3
          "One pig was ill and a vet was looking after it.",  //4
          "Then it started to rain and we got really wet.",   //5
        ],
        answers: { '2-12': 'red', '3-16': 'red', '0-18': 'pink', '4-12': 'pink', '3-0': 'blue', '4-4': 'blue', '4-14': 'blue', '2-0': 'blue', '5-12': 'blue', '0-16': 'blue', '1-2': 'blue', '1-10': 'blue', '5-18': 'blue', '0-10': 'green', '2-6': 'green', '4-2': 'green' },
        initialValue: {},
      },
      Layout: `
      <div style=" display: flex; flex-direction: row; line-height: 48px; ">
        <div>
          <div>It <span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">was</span> <input id='0' type='Circle' /></div>
          <div><input id='1' type='Circle' /></div>
          <div><input id='2' type='Circle' /></div>
          <div><input id='3' type='Circle' /></div>
          <div><input id='4' type='Circle' /></div>
          <div><input id='5' type='Circle' /></div>
        </div>
        <div> <img src='img/FriendsPlus/Page39/E3/1.jpg' /> </div>
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P39-E4',
    audio: 'img/FriendsPlus/Page39/Audio/audio-e4-p39.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page39/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Listen and circle the correct word. Match.<headphone name="46" src="img/FriendsPlus/Page39/Audio/tieude-e4-p39.mp3"></headphone>', color: "#394893" }],
    question: {
      Write: {
        underlineStyle: { border: 'none' },
        inputStyle: { width: 35, textAlign: 'center', maxLength: 1 },
        answers: ['2', '3', '4'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "vest / best_is_blue.", //0
          "bath / path.", //1
          "vet / wet.", //2
        ],
        answers: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;line-height: 58px;justify-content: space-between;width: 900px;">
        <div>
          <div><b>1</b> I really like <b><span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 5px; ">pears</span>  / bears.</b></div>
          <div><b>3</b> Let’s follow this <b><input id='1' type='Circle' /></b></div>
        </div>
        <div>
          <div><b>2</b> My favorite <b><input id='0' type='Circle' /></b>  is blue.</div>
          <div><b>4</b> Oh no! My school work is <b><input id='2' type='Circle' /></b></div>
        </div>        
      </div>
      <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page39/E4/1.jpg' /> </div>
          <div style=" position: absolute; top: 140px; left: 100px; "><input id='0' /></div>
          <div style=" position: absolute; top: 140px; left: 265px; "><input id='1' /></div>
          <div style=" position: absolute; top: 140px; left: 597px; "><input id='2' /></div>
      </div>
      `,
    },
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P39-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E5/1.jpg' },
      ],
    ],
  },
}

export default json;
