import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P76-E1',
    audio: 'img/FriendsPlus/Page76/Audio/audio-e1-p76.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page76/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/2.jpg', audioUrl: "img/FriendsPlus/Page76/Audio/tieude-e1-p76.mp3" },
        { url: 'img/FriendsPlus/Page76/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page76/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page76/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/6.jpg', audioUrl: "img/FriendsPlus/Page76/Audio/cau1.mp3" },
        { url: 'img/FriendsPlus/Page76/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page76/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/9.jpg', audioUrl: "img/FriendsPlus/Page76/Audio/cau2.mp3" },
        { url: 'img/FriendsPlus/Page76/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page76/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/12.jpg', audioUrl: "img/FriendsPlus/Page76/Audio/cau3.mp3" },
        { url: 'img/FriendsPlus/Page76/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page76/E1/14.jpg' },
      ],






    ],
  },

  2: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P76-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page76/E2/1.jpg' },

      ],
    ],
  },

  3: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P76-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page76/E3/Key/answerKey.png',
    inputSize: 850,
    // titleImage: "img/FriendsPlus/Page76/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Now write about what is in the store', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='color:rgb(82,197,235)'>They have some... But they don't have any...</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
        `,
        answer: [
          "They have some shampoo.",
          "They don't have any soap.",
          "They have some newspapers.",
          "They don't have any magazines.",
          "They have some toothpaste.",
          "They don't have any toothbrushes.",
          "They have some chocolates.",
          "They don't have any cookies",
        ],
      },
    ],
  },


  4: { // Exercise num
    unit: 'Unit 10',
    id: 'SB5-U10-P76-E4',
    audio: 'img/FriendsPlus/Page76/Audio/audio-e4-p76.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page76/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page76/E4/2.jpg', audioUrl: "img/FriendsPlus/Page76/Audio/tieude-e4-p76.mp3" },
        { url: 'img/FriendsPlus/Page76/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page76/E4/4.jpg' },

      ],

    ],
  },
}

export default json;