import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P37-E1',
    audio: 'img/FriendsPlus/Page36/Audio/audio-e2-p36.mp3',
    video: 'Videos/e2.p36.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page36/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page36/E2/2.jpg', audioUrl: "img/FriendsPlus/Page36/Audio/tieude-e2-p36.mp3" },
      //   { url: 'img/FriendsPlus/Page36/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page36/E2/4.jpg', audioUrl: "img/FriendsPlus/Page36/Audio/hinh1-e2-p36.mp3" },
        { url: 'img/FriendsPlus/Page36/E2/5.jpg', audioUrl: "img/FriendsPlus/Page36/Audio/hinh2-e2-p36.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page36/E2/6.jpg', audioUrl: "img/FriendsPlus/Page36/Audio/hinh3-e2-p36.mp3" },
        { url: 'img/FriendsPlus/Page36/E2/7.jpg', audioUrl: "img/FriendsPlus/Page36/Audio/hinh4-e2-p36.mp3" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P37-E2',
    audio: 'img/FriendsPlus/Page37/Audio/audio-e2-p37.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/2.jpg', audioUrl: "img/FriendsPlus/Page37/Audio/tieude-e2-p37.mp3" },
        { url: 'img/FriendsPlus/Page37/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E2/5.jpg', audioUrl: "img/FriendsPlus/Page37/Audio/doan1.mp3" },
        { url: 'img/FriendsPlus/Page37/E2/6.jpg', audioUrl: "img/FriendsPlus/Page37/Audio/doan2.mp3" },
        { url: 'img/FriendsPlus/Page37/E2/7.jpg', audioUrl: "img/FriendsPlus/Page37/Audio/doan3.mp3" },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page37/E2/8.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P37-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page37/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/3.jpg', input: true, answer: "didn't buy" },
        { url: 'img/FriendsPlus/Page37/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page37/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/7.jpg', input: true, answer: "saw" },
        { url: 'img/FriendsPlus/Page37/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/9.jpg', input: true, answer: "didn't eat" },
        { url: 'img/FriendsPlus/Page37/E3/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page37/E3/11.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P37-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E4/Key/answerKey.png',
    inputSize: 150,
    // titleImage: "img/FriendsPlus/Page37/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div>
          <div style=" display: flex; flex-direction: row; justify-content: space-between; ">
            <div><img src='img/FriendsPlus/Page37/E4/1.jpg' /></div>
            <div>
              <div>We <sup>1</sup> <u style=" color:rgb(59,203,251); ">&ensp; went &ensp;</u> (go) to a museum to</div>
              <div>learn about the life of</div>
              <div>children 2,000 years ago in</div>
              <div>Greece. Most children</div>
              <div>then <sup>2</sup> #  (not go)</div>
            </div>
          </div>
          <div>to school and <sup>3</sup> #  (not learn) to read.</div>
          <div>Many children <sup>4</sup> #  (play) games in the street</div>
          <div>and had yo-yos and wooden toys. Boys <sup>5</sup> # </div>
          <div>(not wear) pants. They <sup>6</sup> #  (wear) short tunics,</div>
          <div>like dresses.</div>
        </div>
        `,
        answer: [
          "didn't go",
          "didn't learn",
          "played",
          "didn't wear",
          "wore",
        ],
      },
    ],
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB5-U5-P37-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E5/1.jpg' },
      ],
    ],
  },

}

export default json;