import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P11-E1',
    audio: 'img/FriendsPlus/Page11/Audio/audio-e1-p11.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page11/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/2.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/tieude-e1-p11.mp3" },
        { url: 'img/FriendsPlus/Page11/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page11/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page11/E1/5.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/moon.mp3" },
        { url: 'img/FriendsPlus/Page11/E1/6.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/boot.mp3" },
        { url: 'img/FriendsPlus/Page11/E1/7.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/blue.mp3" },
        { url: 'img/FriendsPlus/Page11/E1/8.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/glue.mp3" },
        { url: 'img/FriendsPlus/Page11/E1/9.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/tune.mp3" },
        { url: 'img/FriendsPlus/Page11/E1/10.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/tube.mp3" },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P11-E2',
    audio: 'img/FriendsPlus/Page11/Audio/audio-e2-p11.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page11/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page11/E2/2.jpg', audioUrl: "img/FriendsPlus/Page11/Audio/tieude-e2-p11.mp3" },
        { url: 'img/FriendsPlus/Page11/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page11/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P11-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page11/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read the chant again. Circle the words with <i>oo</i>, <i>ue</i>, and <i>u_e</i>.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, border: 'solid 2px', borderColor: '#2CBAEA' },
        // limitSelect: 2,
        listWords: [
          "It’s Tuesday night,", //0
          "I have glue and tubes,", //1
          "And paint that’s blue.", //2

          "I make a toy flute,", //3
          "I look up at the moon.", //4
          "It’s a hot June night,", //5
          "And I play a tune.", //6
        ],
        answers: ['0-2', '1-8', '1-4', '2-6', '3-8', '4-10', '5-6', '6-8'],
        // initialValue: ['0-2', '1-8', '1-4', '2-6', '3-8', '4-10', '5-6', '6-8'],
        initialValue: [],
      },
      Layout: `
      <div style=" position: relative; width:934px;">
        <div> <img src='img/FriendsPlus/Page11/E3/1.jpg' /> </div>
        <div style="position: absolute;top: 47px;left: 365px;">
          <div>&emsp; <input id='0' type='Circle' /></div>
          <div>And I’m not at <span style=" border: solid 2px #2CBAEA; border-radius: 50%; padding: 3px;">school.</span></div>
          <div><input id='1' type='Circle' /></div>
          <div><input id='2' type='Circle' /></div>
        </div>
        <div style="position: absolute;top: 47px;left: 649px;">
          <div>&emsp; <input id='3' type='Circle' /></div>
          <div><input id='4' type='Circle' /></div>
          <div><input id='5' type='Circle' /></div>
          <div><input id='6' type='Circle' /></div>
        </div>
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P11-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page11/E4/Key/answerKey.png',
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page11/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page11/E4/2.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page11/E4/3.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/4.jpg', input: true, answer: "tube" },
        { url: 'img/FriendsPlus/Page11/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page11/E4/6.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page11/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/8.jpg', input: true, answer: "moon" },
        { url: 'img/FriendsPlus/Page11/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/10.jpg', input: true, answer: "blue" },
        { url: 'img/FriendsPlus/Page11/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page11/E4/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page11/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/14.jpg', input: true, answer: "tune" },
        { url: 'img/FriendsPlus/Page11/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/16.jpg', input: true, answer: "boot" },
        { url: 'img/FriendsPlus/Page11/E4/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page11/E4/18.jpg' },
      ],
    ]
  },
  5: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-U1-P11-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page11/E5/title.jpg' },
      ],
    ],
  },

}

export default json;