import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';

const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P89-E1',
    audio: 'img/FriendsPlus/Page89/Audio/audio-e1-p89.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page89/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/2.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/tieude-e1-p89.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page89/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/5.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/hurt.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/7.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/circle.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page89/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page89/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/11.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/thursday.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/13.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/girl.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page89/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/16.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/nurse.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/18.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/shirt.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page89/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/21.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/curtains.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/23.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/bird.mp3" },
        { url: 'img/FriendsPlus/Page89/E1/24.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page89/E1/25.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P89-E2',
    audio: 'img/FriendsPlus/Page89/Audio/audio-e2-p89.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page89/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/2.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/tieude-e2-p89.mp3" },
        { url: 'img/FriendsPlus/Page89/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page89/E2/4.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/hinh1-e2-p89.mp3" },
        { url: 'img/FriendsPlus/Page89/E2/5.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/hinh2-e2-p89.mp3" },
        { url: 'img/FriendsPlus/Page89/E2/6.jpg', audioUrl: "img/FriendsPlus/Page89/Audio/hinh3-e2-p89.mp3" },
      ],

    ],
  },

  3: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P89-E3',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page89/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read again. Circle the words with ur sounds and underline the words with ir sounds.', color: "#2E479B" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: { padding: 2 },
          circle: { padding: 0.5, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
          underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        // limitSelect: 1,
        listWords: [
          "this girl",	//0
          "hurt her arm. She had to",	//1
          "see the nurse. The girl was",	//2
          "sad because it was her",	//3
          "birthday.",	//4

          "Today was the first time",	//5
          "I wore my purple skirt",	//6
          "and new green shirt.",	//7
          "Look, the skirt has lots of",	//8
          "circles on it.",	//9

          "My curtains are purple.",//10
          "Look! There are lots of",//11
          "birds on them."//12

        ],
        answers: { '1-0': 'circle', '2-4': 'circle', '6-6': 'circle', '10-2': 'circle', '10-6': 'circle', '0-2': 'underline', '2-8': 'underline', '4-0': 'underline', '5-6': 'underline', '6-8': 'underline', '7-6': 'underline', '8-4': 'underline', '9-0': 'underline', '12-0': 'underline' },
        initialValue: {},
      },
      Layout: `
      <div style="position: relative;">
        <div> <img src='img/FriendsPlus/Page89/E3/1.jpg'/> </div>
          <div style="position: absolute;top: 250px;left: 55px;font-size: 21px;">
            <div>Last <span style="padding: 3px; border: 2px solid rgb(66, 133, 244); border-radius: 50%;">Thursday</span> <input id='0' type='Circle' /></div>
            <div><input id='1' type='Circle' /></div>
            <div><input id='2' type='Circle' /></div>
            <div><input id='3' type='Circle' /></div>
            <div><input id='4' type='Circle' /></div>
          </div>
          <div style="position: absolute;top: 250px;left: 365px;font-size: 21px;">
            <div><input id='5' type='Circle' /></div>
            <div><input id='6' type='Circle' /></div>
            <div><input id='7' type='Circle' /></div>
            <div><input id='8' type='Circle' /></div>
            <div><input id='9' type='Circle' /></div>
          </div>
          <div style="position: absolute;top: 317px;left: 675px;font-size: 21px;">
            <div><input id='10' type='Circle' /></div>
            <div><input id='11' type='Circle' /></div>
            <div><input id='12' type='Circle' /></div>
          </div>
      
        
      </div>
      `,
    },
  },

  4: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P89-E4',
    audio: '',
    video: '',
    component: D1,
    fontSize: 22,
    padding: 0,
    textAlign: 'center',
    maxLength: 2,
    exerciseKey: 'img/FriendsPlus/Page89/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page89/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page89/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/3.jpg', input: true, answer: "ur" },
        { url: 'img/FriendsPlus/Page89/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/5.jpg', input: true, answer: "ir" },
        { url: 'img/FriendsPlus/Page89/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/7.jpg', input: true, answer: "ir" },
        { url: 'img/FriendsPlus/Page89/E4/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page89/E4/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page89/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/11.jpg', input: true, answer: "ur" },
        { url: 'img/FriendsPlus/Page89/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/13.jpg', input: true, answer: "ir" },
        { url: 'img/FriendsPlus/Page89/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/15.jpg', input: true, answer: "ir" },
        { url: 'img/FriendsPlus/Page89/E4/16.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/17.jpg', input: true, answer: "ir" },
        { url: 'img/FriendsPlus/Page89/E4/18.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page89/E4/19.jpg' },
      ],

    ],
  },

  5: { // Exercise num
    unit: 'Unit 12',
    id: 'SB5-U12-P89-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page89/E5/1.jpg' },

      ],
    ],
  },
}

export default json;