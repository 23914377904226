import UI from '../../components/ExcerciseTypes/Design/UserInterface';


const json = {
	1: { // Exercise num
		unit: 'Unit 3',
		id: 'SB5-U3-P28-E1',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page28/E1/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page28/E1/2.jpg' },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 3',
		id: 'SB5-U3-P28-E2',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page28/E2/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page28/E2/2.jpg' },
			],
		],
	},


}

export default json;