import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  4: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P71-E4',
    audio: '',
    video: '',
    component: T6,
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page71/E4/Key/answerKey.png',
    inputSize: 50,
    // titleImage: "img/FriendsPlus/Page71/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Read and write T (true) or F (false).', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        
  
        <div style=" display: flex; flex-direction: row; ">
        <div>

          <div><b>1</b> The orange flowers are the least beautiful. <u style='color: rgb(58,193,217);'>&ensp; T &ensp;</u></div>
          <div><b>2</b> The red flowers are the most expensive. #</div>
          <div><b>3</b> The purple flowers are the cheapest. #</div>
          <div><b>4</b> The purple flowers are more expensive than </div>
          
          <div>&ensp;&ensp;the yellow and white ones. #</div>
          <div><b>5</b> The yellow and white flowers are less beautiful</div>
          <div>&ensp;&ensp;than the orange ones.#</div>

          
        
        </div>

        <div><img src='img/FriendsPlus/Page71/E4/1.jpg'/></div>

      </div>

        `,
        answer: [
          "T", "F", "F", "F"
        ],
      },
    ],
  },



  5: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P71-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page71/E5/Key/answerKey.png',
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page71/E5/1.jpg",
    titleQuestion: [{ num: '5', title: 'Write.', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
        
        
        <div style=" display: flex; flex-direction: row; ">
        <div>

          <div> When I grow up, I<sup>1</sup> <u style='color: rgb(58,193,217);'> won't </u> have to go to </div>
          <div>school every day, but I <sup>2</sup> #  have to go to work.</div>
          <div>I want to be a pilot, so I<sup>3</sup> # have to wear a</div>
          <div>pilot's uniform. When I'm a pilot, I <sup>4</sup> # travel</div>
          <div> all around the world and I <sup>5</sup> # see lots of</div>
          <div>places. I <sup>6</sup> # work in an office, but I <sup>7</sup> # sometimes work at night. </div>

        </div>

        <div><img src='img/FriendsPlus/Page71/E5/2.jpg'/></div>

      </div>

        
        `,
        answer: [
          "will", "will", "will", "will", "won't", "will"


        ],
      },
    ],
  },

  6: { // Exercise num
    unit: 'Unit 9',
    id: 'SB5-U9-P71-E6',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    // maxLength: 1,
    checkDuplicated: true,
    exerciseKey: 'img/FriendsPlus/Page71/E6/Key/answerKey.png',
    questionImage: [ // Row

      [
        // Column1
        { url: 'img/FriendsPlus/Page71/E6/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page71/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page71/E6/3.jpg', input: true, answer: "picnic/cucumber" },
        { url: 'img/FriendsPlus/Page71/E6/4.jpg' },
        { url: 'img/FriendsPlus/Page71/E6/5.jpg', input: true, answer: "gloves/yogurt" },
        { url: 'img/FriendsPlus/Page71/E6/6.jpg' },
        { url: 'img/FriendsPlus/Page71/E6/7.jpg', input: true, answer: "orange/page" },
        { url: 'img/FriendsPlus/Page71/E6/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page71/E6/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page71/E6/10.jpg' },
        { url: 'img/FriendsPlus/Page71/E6/11.jpg', input: true, answer: "cucumber/picnic" },
        { url: 'img/FriendsPlus/Page71/E6/12.jpg' },
        { url: 'img/FriendsPlus/Page71/E6/13.jpg', input: true, answer: "police" },
        { url: 'img/FriendsPlus/Page71/E6/14.jpg' },
        { url: 'img/FriendsPlus/Page71/E6/15.jpg', input: true, answer: "yogurt/gloves" },
        { url: 'img/FriendsPlus/Page71/E6/16.jpg' },
        { url: 'img/FriendsPlus/Page71/E6/17.jpg', input: true, answer: "page/orange" },
        { url: 'img/FriendsPlus/Page71/E6/18.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page71/E6/19.jpg' },

      ],




    ],
  },

}

export default json;