import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P47-E1',
    audio: 'img/FriendsPlus/Page47/Audio/audio-e1-p47.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page47/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page47/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/2.jpg', audioUrl: "img/FriendsPlus/Page47/Audio/tieude-e1-p47.mp3" },
        { url: 'img/FriendsPlus/Page47/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page47/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/10.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page47/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/12.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page47/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page47/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page47/E1/17.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page47/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/19.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page47/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/21.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page47/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page47/E1/23.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page47/E1/24.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P47-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page47/E2/Key/answerKey.png',
    inputSize: 40,
    // titleImage: "img/FriendsPlus/Page47/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Listen again and write T (true) or F (false).', color: "#2E479B" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display: flex;flex-direction: row;line-height: 58px;justify-content: space-between;width: 900px;">
            <div>
              <div><b>1</b> Oanh loves playing team sports. <u style=" color:rgb(59,203,251); "> F </u></div>
              <div><b>3</b> Quang does his favorite sport at </div>
              <div>a sports field.  # </div>
            </div>
            <div>
              <div><b>2</b> Son plays his sport in the park. # </div>
              <div><b>4</b> Thu likes playing in a team. # </div>
            </div>
          </div>
        `,
        answer: [
          "F",
          "F",
          "T",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P47-E3',
    audio: '',
    video: '',
    component: UI,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page47/E3/1.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P47-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page47/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page47/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page47/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page47/E4/3.jpg', input: true, answer: "Its" },
        { url: 'img/FriendsPlus/Page47/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page47/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page47/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page47/E4/7.jpg', input: true, answer: "It's" },
        { url: 'img/FriendsPlus/Page47/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page47/E4/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page47/E4/10.jpg' },
      ],

    ],
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P47-E5',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page47/E5/Key/answerKey.png',
    titleQuestion: [{ num: '5', title: 'Circle and write.', color: "#394893" }],
    question: {
      Write: {
        inputStyle: { width: 150, },
        answers: ['', '', '',],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          " It’s / Its", //0
          " It’s / Its", //1
        ],
        answers: ['0-2', '1-6'],
        initialValue: [],
      },
      Layout: `
      <div style='line-height: 58px;'>
        <div>My favorite sport is <input id='0' />. <input id='0' type='Circle' /> great. I like it because <input id='1' />.</div>
        <div><input id='1' type='Circle' /> rules are <input id='2' />.</div>
      </div>
      `,
    },
  },

}

export default json;